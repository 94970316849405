<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="notice-create-header-div">
      <span><strong>公告资讯</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="notice-create-content-div">
        <div class="notice-create-base-info">
          公告创建
        </div>
        <div class="notice-create-base-content">

          <el-form :inline="true"  label-width="150px">
            <el-form-item label="类型:">
              <el-select v-model="typeSelect" style="width: 300px" placeholder="请选择">
                <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>

          <el-form :inline="true"  label-width="150px">
            <el-form-item label="自动回复关键字:">
              <el-input style="width: 300px" v-model="keyword">
              </el-input>
            </el-form-item>
          </el-form>

          <el-form :inline="true"  label-width="150px">
            <el-form-item label="自动回复内容:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="content"/>
            </el-form-item>
          </el-form>
        </div>

        <div class="notice-create-pagination-div">
          <el-button type="primary" @click="commit()">创建</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/autoResponse/autoResponseCreate.css";
import store, {request} from "../../store";

export default {
  data() {
    return {
      typeSelect: '',
      typeList: [],
      keyword: '',
      content: ''
    }
  },

  components: {
  },

  mounted() {
    this.initFilterdata()
    store.state.courseType = 'DEFAULT'
  },

  methods: {

    // 初始化下拉框数据
    initFilterdata() {
      let response = request('get', 'auto/response/filterdata', null);
      response.then(data => {
        this.typeList = this.initGroupType(data.data, 'TYPE', true)
      });
    },

    initGroupType(dataList, typeName, defaultEmpty) {
      for (let i = 0; i < dataList.length; i++) {
        let meta = dataList[i]
        if (meta.name != typeName) {
          continue
        }
        let all = {};
        all.label = '全部';
        all.value = '';
        let mdlist = [];
        if (!defaultEmpty) {
          mdlist.push(all)
        }
        meta.dataList.forEach(m => {
          let md = {};
          md.value = m.name;
          md.label = m.name;
          mdlist.push(md);
        })
        return mdlist;
      }
    },

    handleRemove(files) {
      if (files.length > 0) {
        this.img = files[0].url
      }
      this.fileList = files
    },

    cancel() {
      this.$router.go(-1)
    },

    commit() {
      if (this.typeSelect == '') {
        this.message("回复类型不能为空！");
        return;
      }

      if (this.content == '') {
        this.message("回复内容不能为空！");
        return;
      }

      let param = {
        type: this.typeSelect,
        keyword: this.keyword,
        responseContent: this.content,
      }
      let response = request('post', 'auto/response/create', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("创建成功！")
          this.$router.push({  //核心语句
            path:'/auto-response-manager',   //跳转的路径
          })
        } else {
          this.message("创建失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>