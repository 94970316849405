<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="buy">

    <div class="buy-div">
      <div class="buy-top">
        微信支付
      </div>
      <div class="buy-commodity-name">
        {{this.commodityName}}
      </div>
      <div class="buy-price">
        ￥{{this.displayPrice}}
      </div>
      <div class="buy-payee">
        收款方：{{this.payee}}
      </div>
<!--      <div class="payment-protocol-div">-->
<!--        <span @click="dialogShow=true">商品购买协议</span>-->
<!--      </div>-->
      <div class="buy-button">
        <el-button type="primary" @click="buy()">立即支付</el-button>
      </div>
    </div>

    <el-dialog title="商品购买协议" style="color: #EDB244; font-size: 16px;" :visible.sync="dialogShow" width="340px">
      <div>
        <table class="payment-protocol-signed-table">
          <tr>
            <td colspan="2">
              <div class="payment-protocol-list" v-for="content in paymentProtocolContentList" :key="content">
                <p class="payment-protocol-format">
                  &emsp;&emsp;{{content.content}}
                </p>
              </div>
            </td>
          </tr>
          <tr class="payment-protocol-signed-tr">
            <td class="payment-protocol-signed-td">
              <el-radio-group>
                <el-checkbox @change="signPaymentProtocol"/>
              </el-radio-group>
            </td>
            <td class="payment-protocol-confirm-td">我已阅读协议并自愿签署！</td>
          </tr>
          <tr class="payment-protocol-signed-tr">
            <td colspan="2">
              <el-button type="primary" @click="dialogShow=false">关闭</el-button>
            </td>
          </tr>
        </table>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import "../../../css/wx/commodity/buyCourse.css";
 import {request} from "../../../store";

const WeixinJSBridge = require("q");
// import wx from 'weixin-js-sdk' // 引入微信js-sdk
// import store from '../../store';

export default {
  name: 'BuyCourse',
  data () {
    return {
      commodityId: this.$route.query.commodityId,
      outTradeNo: this.$route.query.outTradeNo,
      commodityName: '',
      price: '',
      displayPrice: '',
      payee: '',
      timer: '',
      protocolId: '',
      paymentProtocolContentList: '',
      signed: false,
      dialogShow: false
    }
  },

  mounted() {
    this.getCommodity()
    this.selectPaymentProtocol()
    this.timer = setInterval(() => {
      setTimeout(this.refresh(), 2 * 1000)
    }, 2 * 1000)
  },

  destroyed() {
    clearInterval(this.timer);
  },

  methods: {
    getCommodity() {
      let response = request('get', 'wx/commodity/get?id=' + this.commodityId);
      response.then(data => {
        if (data.code == 200) {
              this.commodityName = data.data.name
              this.price = data.data.price
              this.displayPrice = data.data.displayPrice
              this.payee = data.data.payee
        }
      })
    },

    selectPaymentProtocol() {
      let response = request('get', 'wx/protocol/get');
      response.then(data => {
        if (data.code == 200
            && data.data.contents != null
            && data.data.contents != ''
            && data.data.contents != undefined) {
          this.protocolId = data.data.id
          this.paymentProtocolContentList = data.data.contents
        }
      })
    },

    signPaymentProtocol() {
      if (this.signed) {
        this.signed = false
      } else {
        this.signed = true
      }
    },

    refresh() {
      let response = request('get', 'wx/payment/get?commodityId=' + this.commodityId, null);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null) {
            clearTimeout(this.timer)
            history.go(-1);
          }
        }
      })
    },

    buy() {
      // if (!this.signed) {
      //   this.message("请先签署商品购买协议！")
      //   return
      // }
      let response = request('get', 'wx/pay/getPayInfo?commodityId=' + this.commodityId
          + "&outTradeNo=" + this.outTradeNo + "&protocolId=" + this.protocolId);
      response.then(data => {
        if (data.code == 200) {
          let self = this;
          let advancePaymentInfo = data.data
          this.message(advancePaymentInfo)
          this.message(WeixinJSBridge)
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener( "WeixinJSBridgeReady", this.onBridgeReady(advancePaymentInfo),
                  false
              );
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", self.onBridgeReady(advancePaymentInfo)
              );
              document.attachEvent("onWeixinJSBridgeReady", self.onBridgeReady(advancePaymentInfo)
              );
            }
          } else {
            self.onBridgeReady(advancePaymentInfo);
          }
        }
      })
    },

    onBridgeReady(data) {
      let self = this;
      window.WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: data.appId, //公众号名称，由商户传入
            timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
            nonceStr: data.nonceStr, //随机串
            package: data.packageInfo,
            signType: data.signType, //微信签名方式：
            paySign: data.paySign //微信签名
          },
          function(res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              self.success("支付成功");
            } else if (res.err_msg === "get_brand_wcpay_request:fail") {
              self.error("支付失败");
            }
          }
      );
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>
<style >


</style>