<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

<!--  头部-->
  <div class="monthly-training">

<!--  训练内容-->
    <div class="monthly-training-content">
      <tr>
        <td colspan="2" class="monthly-training-title">{{this.title}}</td>
      </tr>
      <tr>
        <td class="monthly-training-space-td"></td>
      </tr>
      <div class="monthly-training-notice-list">
        <div class="monthly-training-notice-content" v-for="notice in noticeList" :key="notice">
          <p>
          &emsp;{{notice.content}}
          </p>
        </div>
      </div>

      <div class="monthly-training-list" v-for="template in inputList" :key="template">
        <div class="monthly-training-content-title">
          {{template.title}}
        </div>
        <div class="monthly-training-content-textarea">
          <el-form :inline="true">
            <el-form-item>
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 320px; background-color: #FFFFFF; margin-left: auto; margin-right: auto"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="template.content"/>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="monthly-training-button">
        <el-button type="primary" @click="commit('PRIVATE', 'TRAINING')">提交不公开</el-button>
        <el-button type="primary" @click="commit('PUBLIC', 'TRAINING')">提交并公开</el-button>
      </div>
    </div>

    <div class="bottom-div">
    </div>

  </div>
</template>

<script>

import "../../../css/wx/train/monthlyTraining.css";
import "../../../css/wx/contents.css";
import {request} from "../../../store";
// import store from '../../store';
// import {request, timeTransform} from "../../store";

export default {

  data () {
    return {
      title: this.$route.query.title,
      lessonId: this.$route.query.id,
      courseId: this.$route.query.courseId,
      noticeList: [],
      inputList: []
    }
  },

  mounted() {
    this.initTemplateList()
  },

  methods: {
    initTemplateList() {
      let inputList = []
      let templateList = this.$route.query.templateList
      for (let i = 0; i < templateList.length; i++) {
        let input = {
          title: templateList[i].title,
          content: '',
        }
        inputList.push(input)
      }
      this.inputList = inputList

      let noticeList = []
      let teainingNoticeList = this.$route.query.noticeList
      for (let i = 0; i < teainingNoticeList.length; i++) {
        let notice = {
          content: teainingNoticeList[i].content
        }
        noticeList.push(notice)
      }
      this.noticeList = noticeList
    },

    commit(status, type) {
      let trainingContents = []
      for (let i = 0; i < this.inputList.length; i++) {
        let input = this.inputList[i]
        if (input.title == '' || input.content == '') {
          this.message("请填写完整后再提交！")
          return;
        }
        let content = {
          title: input.title,
          content: input.content
        }
        trainingContents.push(content)
      }

      let param = {
        courseId: this.courseId,
        lessonId: this.lessonId,
        content: trainingContents,
        status: status,
        type: type
      }
      let response = request('post', 'wx/training/create', param);
      response.then(data => {
        if (data.code == 200) {
          this.isTrained = true
          this.$router.go(-1)
          this.message("提交成功！")
        } else {
          this.message("提交失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>