<template>
  <div class="text-msg-pic-upload">
    <el-upload
        :class="{ display: uploadDisabled }"
        list-type="picture-card"
        ref="upload"
        action
        multiple
        :http-request="handleUpload"
        :auto-upload="autoUpload"
        :limit="limit"
        :file-list="tempFileList"
        :on-exceed="handleExceed"
        :on-success="handleSuccess"
        :on-remove="handleRemove"
        :before-remove="beforeRemove"
        :before-upload="beforeUpload"
        :on-preview="handlePictureCardPreview"
    >
      <i class="el-icon-plus"></i>
      <div slot="tip" class="el-upload__tip" v-if="tipsFlag">{{ tips }}</div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <div style="margin-left: auto; margin-right: auto; margin-bottom: 20px; width: 300px">
        {{dialogFileName}}
      </div>
      <div style="margin-left: auto; margin-right: auto; width: 300px">
        <audio width="100%" :src="dialogFileUrl" controls="controls" ref="audio" />
      </div>
    </el-dialog>
    <div class="Upload_pictures">
      <ul class="el-upload__tip cBBBDBF" style="color: #BBBDBF;">
        <li>只能上传2个文件，不超过200MB。</li>
      </ul>
    </div>
  </div>
</template>

<script>
import upload from '../../tools/aliyunUpload'
import store from "../../store";
export default {
  name: 'UploadImageDemo',
  props: {
    width: {
      type: String,
      default: '240px'
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    limit: {
      type: Number,
      default: 1
    },
    // limitType: {
    //   type: Array,
    //   default: () => ['video/mp4', 'video/ogg','video/flv','video/avi','video/wmv','video/rmvb']
    // },
    disabled: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default: () => []
    },
    tipsFlag: {
      type: Boolean,
      default: false
    },
    tips: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 上传文件列表，el-upload使用，临时保存数据。
      tempFileList: this.fileList,
      uploadDisabled: store.state.uploadDisabled,
      host: '', // 阿里云上传服务器地址根路径
      dialogFileName: '',
      dialogFileUrl: '',
      dialogVisible: false
    }
  },
  watch: {
    // 解决第二渲染接口， 图片还保留着原来的问题 JerryYi
    fileList: {
      immediate: true,
      handler(val) {
        this.tempFileList = val
      }
    }
  },
  computed: {
    upText() {
      return this.autoUpload ? '上传文件' : '选择文件'
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {

    handlePictureCardPreview(file) {
      this.dialogFileUrl = file.url
      this.dialogFileName = file.name
      this.dialogVisible = true
    },

    beforeUpload(file) {
      // console.log('beforeUpload', file)
      // let types = this.limitType
      // const isImage = types.includes(file.type)
      const isLt200M = file.size / 1024 / 1024 < 200

      // if (!isImage) {
      //   this.$message({
      //     message: '上传文件只能是mp4、ogg、flv、avi、wmv、rmvb格式!',
      //     type: 'warning'
      //   })
      //   return false
      // }
      if (!isLt200M) {
        this.$message.error('上传图片大小不能超过 200MB!')
        return false
      }
      // return isImage && isLt200M
      return isLt200M
    },
    // 自定义上传操作
    handleUpload(op) {
      // let dir = store.state.courseType
      upload(
          op.file,
          res => {
            let temp = {
              name: res.attachment,
              url: res.host + '/' + res.aliyunAddress
            }
            this.host = res.host
            op.onSuccess(temp)
          },
          err => {
            console.log(err)
          },
          res => {
            op.onProgress(res)
          },
          store.state.courseType
      )
    },
    // 上传成功后触发
    handleSuccess(response, file, fileList) {
      this.filterFileFn(fileList)
    },
    // 返回给接口要用的格式
    filterFileFn(fileList) {
      let filterArr = fileList
          .filter(item => !item.status || item.status !== 'ready') // 过滤未上传的文件
          .map(item => {
            let url = item.response ? item.response.url : item.url
            return {
              url: url, // item.url || item.response.url
              name: item.name
            }
          })
      // console.log('fileList', fileList)
      this.$emit('onSuccessFiles', filterArr)
      if (filterArr.length > 1) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }
    },

    // 监听移除文件列表
    handleRemove(file, fileList) {
      if (file.status === 'success') {
        this.filterFileFn(fileList)
        this.uploadDisabled = false;
        let filterArr = fileList
            .filter(item => !item.status || item.status !== 'ready') // 过滤未上传的文件
            .map(item => {
              let url = item.response ? item.response.url : item.url
              return {
                url: url, // item.url || item.response.url
                name: item.name
              }
            })
        this.$emit('handleRemove', filterArr)
      }
    },
    handleExceed(files, fileList) {
      console.log(files, fileList)
      //this.$message({ message: `当前限制选择 ${this.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`, type: 'warning' })
    },
    beforeRemove() {
      // return this.$confirm(`确定移除 ${file.name}？`)
    }
  }
}
</script>

<style>
.text-msg-pic-upload .el-upload--picture-card,
.text-msg-pic-upload .el-upload-list--picture-card .el-upload-list__item {
  width: 62px;
  height: 62px;
  line-height: 72px;
}
.display .el-upload--picture-card {
  display: none;
}
</style>
