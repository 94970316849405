<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="hundred-day">

  <!-- 课程简介 -->
    <div class="monthly-introduction">
      <tr>
        <td class="course-introduction">课程简介</td>
      </tr>
      <tr>
        <td class="course-introduction">{{this.title}}</td>
      </tr>
      <div class="author-img-div">
        <img class="author-img" src="https://mental-training.oss-cn-hangzhou.aliyuncs.com/img/taoge.jpeg"/>
      </div>
      <div class="author-name-div">
        涛哥交易心理训练
      </div>
      <tr>
        <td class="course-introduction-content">
          <div class="monthly-comment-list" v-for="content in course.contentList" :key="content">
            <p>
              &emsp;&emsp;{{content.content}}
            </p>
          </div>
          <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{createTime}}</p>
        </td>
      </tr>

    </div>

    <!-- 购买课程 -->
    <div class="hundred-day-course-pay">
      <if v-if="!this.bought">
        <el-button type="primary" @click="pay()">购买课程</el-button>
      </if>
      <if v-if="this.bought">
        <el-button type="primary" disabled="false">已购买</el-button>
      </if>
    </div>

    <!-- 课程列表 -->
    <div class="hundred-day-course-list">
      <tr>
        <td class="course-introduction">课程列表</td>
      </tr>
      <div class="trained-course-data" v-for="lesson in trainedLessonList" :key="lesson" @click="goToHundredDayLesson(lesson.id, lesson.isOpen)">
        <div class="course-icon-div">
          <img class="course-icon-img" src="https://mental-training.oss-cn-hangzhou.aliyuncs.com/img/taoge.jpeg"/>
        </div>
        <div class="course-content">
          <tr>
            <td class="course-content-title">
              {{lesson.name}}
            </td>
          </tr>
          <tr>
            <td class="course-content-text">
              {{lesson.desc}}
            </td>
          </tr>
        </div>
      </div>

<!--      <div class="no-trained-course-data" v-for="lesson in lessonList" :key="lesson" @click="goToHundredDayLesson(lesson.id, lesson.isOpen)">-->
<!--        <div class="course-icon-div">-->
<!--          <img class="course-icon-img" src="https://mental-training.oss-cn-hangzhou.aliyuncs.com/img/taoge.jpeg"/>-->
<!--        </div>-->
<!--        <div class="course-content">-->
<!--          <tr>-->
<!--            <td class="course-content-title">-->
<!--              {{lesson.name}}-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="course-content-text">-->
<!--              {{lesson.desc}}-->
<!--            </td>-->
<!--          </tr>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <div class="bottom-div">

    </div>

  </div>
</template>

<script>

import "../../css/wx/hundredDays.css";
import {request, timeTransform} from "../../store";
// import {request} from "../../store";
// import store from '../../store';

export default {
  name: 'WxIndex',
  components: {

  },
  data () {
    return {
      bought: false,
      courseId: this.$route.query.id,
      trainedLessonList: [],
      lessonList: [],
      course: '',
      createTime: ''
    }
  },

  mounted() {
    this.initCourseInfo()
    this.selectBuyCourseRecord()
    this.initLessonList()
  },

  methods: {
    initCourseInfo() {
      let response = request('get', 'wx/course/get?id=' + this.courseId, null);
      response.then(data => {
        if (data.code == 200) {
          this.course = data.data
          this.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii")
        } else {
          this.message("查询失败！")
        }
      })
    },

    selectBuyCourseRecord() {
      let response = request('get', 'wx/payment/get?courseId=' + this.courseId, null);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null) {
            this.bought = true
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    initLessonList() {
      let param = new Map();
      param.set("status", 'ONLINE')
      param.set("courseId", this.courseId)
      param.set("pageNum", 1);
      param.set("pageSize", 30);
      let response = request('get', 'wx/lesson/list', param);
      response.then(data => {
        if (data.code == 200) {
          this.courseList = []
          let lessonList = data.data.data;
          for (let i = 0; i < lessonList.length; i++) {
            let lesson = lessonList[i]
            let lessonData = {
              id: lesson.id,
              name: lesson.name,
              isOpen: lesson.isOpen,
              desc: lesson.desc,
              type: lesson.type,
              img: lesson.img
            }
            if (lesson.isOpen) {
              this.trainedLessonList.push(lessonData)
            }
            // else {
            //   this.lessonList.push(lessonData)
            // }
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    pay() {
      this.$router.push({
        path: '/payment-record',
        query: {
          id: this.courseId
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },

    goToHundredDayLesson(id, isOpen) {
      if (!this.bought) {
        this.message("请先购买课程！")
        return
      }
      if (!isOpen) {
        this.message("请先按顺序进行训练！")
        return
      }
      this.$router.push({
        path: '/hundred-days-lesson',
        query: {
          id: id
        }
      })
    },

  }

}
</script>
<style >


</style>