<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="monthly">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
    <div class="monthly-introduction">

      <tr>
        <td class="course-introduction">{{course.name}}</td>
      </tr>
      <div class="author-img-div">
        <img class="author-img" :src="masterHeadPortrait"/>
      </div>
      <div class="author-name-div">
        {{masterName}}
      </div>
      <tr>
        <td class="course-introduction-content">
          <div class="monthly-comment-list" v-for="content in course.contentList" :key="content">
            <p class="paragraph-format">
              &emsp;&emsp;{{content.content}}
            </p>
          </div>
          <p class="paragraph-format">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{createTime}}</p>
        </td>
      </tr>

    </div>

    <!-- 购买课程 -->
    <div class="course-operation-div">
      <if v-if="!this.bought">
        <el-button type="primary" @click="pay()">购买课程</el-button>
      </if>
      <if v-if="this.bought">
        <el-button type="primary" disabled="false">已购买</el-button>
      </if>
    </div>

  <!-- 课程列表 -->
    <div class="monthly-record-comment-split-line"></div>
    <div class="monthly-course-list">
      <tr>
        <td class="course-introduction" v-if="this.bought">
<!--          <el-button type="primary" @click="showIntroduction()">课程列表</el-button>-->
          <span id="introduction" @click="showIntroduction()">课程列表</span>
        </td>
        <td class="course-comment">
<!--          <el-button type="primary" @click="showComment()">评论列表</el-button>-->
          <span id="comment" @click="showComment()">评论列表</span>
        </td>
      </tr>

      <div v-if="courseIntroduction && this.bought">
        <div class="trained-course-tab-div">
          <div class="trained-course-tab" v-for="(tab, index) in tabList" :key="tab">
            <el-button :id="index" :plain="false" @click="changeLessonTab(tab.name)">
              {{tab.name}}
            </el-button>
          </div>
        </div>

        <div class="trained-course-data" v-for="lesson in trainedLessonList" :key="lesson" @click="goToLesson(lesson.id, lesson.isOpen)">
          <div class="course-icon-div">
            <img class="course-icon-img" src="https://mental-training.oss-cn-hangzhou.aliyuncs.com/img/taoge.jpeg"/>
          </div>
          <div class="course-content">
            <tr>
              <td class="course-content-title">
                {{lesson.name}}
              </td>
            </tr>
            <tr>
              <td class="course-content-text">
                {{lesson.desc}}
              </td>
            </tr>
          </div>
        </div>
      </div>

      <div class="comment" v-if="courseComment">
        <div class="no-comment-div" v-if="commentList.length == 0">
          暂无评论，快来分享吧！
        </div>

        <div v-if="currentCommentList.length != 0">
          <div class="comment-list-div" v-for="comment in currentCommentList" :key="comment">
            <table class="comment-table">
              <tr>
                <td class="comment-user-head-portrait-td">
                  <img class="comment-user-head-portrait" :src="comment.headPortrait"/>
                </td>
                <td class="comment-user-nickname-td">{{comment.nickname}}</td>
                <td class="comment-user-create-time-td">{{comment.createTime}}</td>
              </tr>
              <tr>
                <td colspan="3" class="comment-user-content-td">
                  {{comment.content}}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="comment-div" v-if="commentList.length != 0">

          <div class="comment-list-div" v-for="comment in commentList" :key="comment">
            <table class="comment-table">
              <tr>
                <td class="comment-user-head-portrait-td">
                  <img class="comment-user-head-portrait" :src="comment.headPortrait"/>
                </td>
                <td class="comment-user-nickname-td">{{comment.nickname}}</td>
                <td class="comment-user-create-time-td">{{comment.createTime}}</td>
              </tr>
              <tr>
                <td colspan="3" class="comment-user-content-td">
                  {{comment.content}}
                </td>
              </tr>
            </table>
          </div>
          <div class="bottom-div">

          </div>
        </div>

        <mt-tabbar fixed class="foot" v-if="this.bought">
          <el-input placeholder="请输入信息"
                    @focus="add"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 20}"
                    v-model="commentContent">
          </el-input>
          &nbsp;
          <el-button type="warning" @click="send">发送</el-button>
        </mt-tabbar>

      </div>

    </div>
    <div class="bottom-div">

    </div>
    </mescroll-vue>

  </div>
</template>

<script>

import "../../css/wx/course.css";
import "../../css/wx/contents.css";
import "../../css/wx/comment.css";
import store, {request, timeTransform} from "../../store";
import MescrollVue from 'mescroll.js/mescroll.vue'
import MintUI from 'mint-ui';
import 'mint-ui/lib/style.css'
import Vue from 'vue';
Vue.use(MintUI)

export default {
  name: 'WxIndex',
  components: {
    MescrollVue
  },
  data () {
    return {
      masterName: store.state.masterName,
      masterHeadPortrait: store.state.masterHeadPortrait,
      bought: false,
      courseId: this.$route.query.id,
      tabList: [],
      trainedLessonList: [],
      lessonList: [],
      course: '',
      createTime: '',

      courseIntroduction: true,
      courseComment: false,

      showCommenttTextarea: false,
      currentCommentList: [],
      commentContent: '',
      commentList: [],

      mescrollDown:{
        callback: this.refreshPage,
      },
      mescrollUp: {
        auto: true, // 是否在初始化时以上拉加载的方式自动加载第一页数据; 默认false
        page: {
          num: 0,
          size: 10
        },
        callback: this.loadData,
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
      },
      mescroll: ''
    }
  },

  mounted() {
    this.selectUserInfo()
    this.initLessonList()
    this.initCourseInfo()
    this.selectBuyCourseRecord()
  },

  methods: {
    initCourseInfo() {
      let response = request('get', 'wx/course/get?id=' + this.courseId, null);
      response.then(data => {
        if (data.code == 200) {
          this.course = data.data
          this.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii")
          if (this.mescroll != '') {
            this.mescroll.endSuccess()
          }
        }
      })
    },

    selectBuyCourseRecord() {
      let response = request('get', 'wx/course/fee/paid?courseId=' + this.courseId, null);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null && data.data != undefined) {
            this.bought = data.data
            if (!this.bought) {
              this.showComment()
            } else {
              this.showIntroduction()
            }
          }
        }
      })
    },

    selectUserInfo() {
      let response = request('get', '/wx/user/info');
      response.then(data => {
        store.state.userInfo = data.data
      })
    },

    initLessonList() {
      let param = new Map();
      param.set("status", 'ONLINE')
      param.set("courseId", this.courseId)
      param.set("pageNum", 1);
      param.set("pageSize", 100);
      let response = request('get', 'wx/lesson/list', param);
      response.then(data => {
        if (data.code == 200) {
          this.courseList = []
          let tabList = []
          let list = data.data;
          if (list.length == 0) {
            return
          }
          list.forEach(tab => {
            let tmpLessonList = []
            let tapLessonList = tab.dataList;
            for (let i = 0; i < tapLessonList.length; i++) {
              let lesson = tapLessonList[i]
              let lessonData = {
                id: lesson.id,
                name: lesson.name,
                isOpen: lesson.isOpen,
                desc: lesson.desc,
                img: lesson.img
              }
              //tmpLessonList.push(lessonData)
              if (lesson.isOpen) {
                tmpLessonList.push(lessonData)
              }
              // else {
              //   this.lessonList.push(lessonData)
              // }
            }
            let tmp = {
              name: tab.tab,
              dataList: tmpLessonList
            }
            if (tmpLessonList.length != 0) {
              tabList.push(tmp)
            }
          })
          this.tabList = tabList
          this.trainedLessonList = tabList[0].dataList
          setTimeout(function (){
            for (let i = 0; i < tabList.length; i++) {
              let tab = tabList[i]
              if (tab.name == tabList[0].name) {
                this.trainedLessonList = tab.dataList
                let buttons = document.getElementById(i)
                buttons.style.backgroundColor="#bbbbbb"
              } else {
                let buttons = document.getElementById(i)
                buttons.style.backgroundColor="#efefef"
              }
            }
          }, 500);
        } else {
          this.message("查询失败！")
        }
      })
    },

    changeLessonTab(tabName) {
      for (let i = 0; i < this.tabList.length; i++) {
        let tab = this.tabList[i]
        if (tab.name == tabName) {
          this.trainedLessonList = tab.dataList
          let buttons = document.getElementById(i)
          buttons.style.backgroundColor="#bbbbbb"
        } else {
          let buttons = document.getElementById(i)
          buttons.style.backgroundColor="#efefef"
        }
      }
    },

    goToLesson(id, isOpen) {
      if (store.state.userInfo.status == undefined || store.state.userInfo.status == 'UNREGISTERED') {
        this.goToRegister()
        return
      }
      if (!this.bought) {
        this.message("请先购买课程！")
        return
      }
      if (!isOpen) {
        this.message("请先按顺序进行训练！")
        return
      }
      this.$router.push({
        path: '/monthly-lesson',
        query: {
          id: id
        }
      })
    },

    pay() {
      if (store.state.userInfo.status == undefined || store.state.userInfo.status == 'UNREGISTERED') {
        this.goToRegister()
        return
      }
      this.$router.push({
        path: '/commodity-detail',
        query: {
          id: this.course.defaultCommodity
        }
      })
    },

    goToRegister() {
      this.$router.push({
        path: '/register',
      })
    },

    generateOutTradeNo() {
      let date = timeTransform(new Date(), "YYYYMMDD")
      return "4200001" + this.getRandom(3) + date + this.getRandom(10)
    },

    getRandom(len) {
      len = len || 32;
      var $chars = '0123456789';
      var maxPos = $chars.length;
      var pwd = '';
      for (var i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },

    showIntroduction() {
      this.courseIntroduction = true
      this.courseComment = false
      let introduction = document.getElementById("introduction")
      let comment = document.getElementById("comment")
      introduction.style.color = '#000000'
      comment.style.color = '#888888'
    },

    showComment() {
      this.currentCommentList = []
      // this.loadData(this.mescrollUp.page)
      this.courseIntroduction = false
      this.courseComment = true
      let introduction = document.getElementById("introduction")
      let comment = document.getElementById("comment")
      comment.style.color = '#000000'
      introduction.style.color = '#888888'
    },

    mescrollInit (mescroll) {
      this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },

    refreshPage() {
      this.initCourseInfo()
    },

    loadData(page) {
      // 联网加载数据
      this.selectComment(page.num, page.size, (curPageData) => {
        // 添加列表数据
        this.commentList = this.commentList.concat(curPageData)
        // 数据渲染成功后,隐藏下拉刷新的状态
        this.$nextTick(() => {
          this.mescroll.endSuccess(curPageData.length)
        })
      }, () => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        this.mescroll.endErr()
      })
    },

    selectComment(pageNum, pageSize, successCallback, errorCallback) {
      let param = new Map();
      if (this.courseId == '' || this.courseId == undefined) {
        this.message("课程id无效！")
        return
      }
      param.set("linkedId", this.courseId);
      param.set("status", "ONLINE")
      param.set("pageNum", pageNum);
      param.set("pageSize", pageSize);
      let response = request('get', 'wx/comment/list', param);
      response.then(data => {
        if (data.code == 200) {
          let commentList = []
          let recordList = data.data.data;
          if (recordList == null || recordList.length == 0) {
            successCallback && successCallback(commentList)
            return;
          }
          recordList.forEach(comment => {
            let temp = {
              id: comment.id,
              nickname: comment.nickname,
              headPortrait: comment.headPortrait,
              content: comment.content,
              createTime: timeTransform(new Date(comment.createTime), "YYYY-MM-DD HH:ii:ss")
            }
            commentList.push(temp)
          })
          successCallback && successCallback(commentList)
        } else {
          this.message("查询失败！")
          errorCallback && errorCallback()
        }
      })
    },

    add() {
      console.log(this.courseComment)
      this.$nextTick(function () {
        document.documentElement.scrollTop = 1000000;
      });
      setTimeout(() => {
        // var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        document.documentElement.scrollTop = 10000;

      }, 100);
    },

    send() {
      if (this.commentContent == '') {
        this.message("评论内容不能为空！")
        return;
      }
      let param = {
        linkedId: this.courseId,
        content: this.commentContent
      }
      let response = request('post', 'wx/comment/create', param);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null && data.data != undefined && data.data) {
            this.message("评论成功！")
            let currentComment = {
              nickname: store.state.userInfo.nickname,
              headPortrait: store.state.userInfo.headPortrait,
              content: this.commentContent,
              createTime: timeTransform(new Date(), "YYYY-MM-DD HH:ii:ss")
            }
            let list = []
            list.push(currentComment)
            this.currentCommentList.forEach(comment => {
              list.push(comment)
            })
            this.currentCommentList = list
            this.commentContent = ''
          }
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }
  }

}
</script>
<style >


</style>