<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="wx-message-create-header-div">
      <span><strong>微信消息创建</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="wx-message-create-content-div">
        <div class="wx-message-base-info">
          微信消息创建
        </div>
        <div class="wx-message-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="消息类型:">
              <el-select v-model="messageType" style="width: 300px" placeholder="请选择">
                <el-option
                    v-for="item in messageTypeList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课程名称">
              <el-input style="width: 300px" v-model="lessonName" placeholder="">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="发送时间">
              <el-input style="width: 300px" v-model="sendTime" placeholder="格式  yyyy-MM-dd HH:mm:ss">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="开始时间">
              <el-input style="width: 300px" v-model="startTime" placeholder="格式  yyyy-MM-dd HH:mm:ss">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="直播地址">
              <el-input style="width: 300px" v-model="url" placeholder="">
              </el-input>
            </el-form-item>
          </el-form>
        </div>

        <div class="wx-message-base-content">
          <table class="course-info-table">
            <tr>
              <td class="course-info-td">课程</td>
              <td class="course-info-td">操作</td>
            </tr>
            <tr v-for="course in linkedCourseList" :key="course">
              <td class="course-info-option">{{course.name}}</td>
              <td class="course-info-option">
                <el-button @click="removeCourse(course.id)">删除</el-button>
              </td>
            </tr>
          </table>
          <div class="add-label-button">
            <el-button type="primary" @click="showCourseDialog()" >
              +添加课程
            </el-button>
          </div>
        </div>

<!--         -->

        <div class="wx-message-pagination-div">
          <el-button type="primary" @click="commit()">创建</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>

        <el-dialog title="场景标签" style="color: #EDB244; font-size: 40px;" :visible.sync="courseDialogFormVisible" width="710px">
          <div id="statistical-data-table" class="data-table-div">
            <el-select v-model="courseId"
                       aria-label="课程:"
                       placeholder="请选择">
              <el-option
                  v-for="item in showCourseList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div slot="footer">
            <el-button @click="addCourse()">添加</el-button>
            <el-button @click="courseDialogFormVisible=false">取消</el-button>
          </div>
        </el-dialog>

      </div>
    </div>

  </div>
</template>

<script>

import "../../css/message/wxMessageCreate.css";
import {request} from "../../store";

export default {
  data() {
    return {
      messageType: '',
      messageTypeList: [],
      courseId: '',
      courseList: [],
      linkedCourseList: [],
      showCourseList: [],
      lessonName: '',
      sendTime: '',
      startTime: '',
      url: '',
      content: '',
      courseDialogFormVisible: false
    }
  },

  components: {
  },

  mounted() {
    this.initSelectFilterdata();
    this.selectCourseList();
  },

  methods: {
    initSelectFilterdata() {
      let response = request('get', 'message/filterdata', null);
      response.then(data => {
        let dataList = data.data
        this.messageTypeList = this.initGroupType(dataList, 'TYPE', true)
      });
    },

    initGroupType(dataList, typeName, defaultEmpty) {
      for (let i = 0; i < dataList.length; i++) {
        let meta = dataList[i]
        if (meta.name != typeName) {
          continue
        }
        let all = {};
        all.id = '';
        all.name = '全部';
        let mdlist = [];
        if (!defaultEmpty) {
          mdlist.push(all)
        }
        meta.dataList.forEach(m => {
          let md = {};
          md.name = m.name;
          md.id = m.id;
          md.value = m.expand
          mdlist.push(md);
        })
        return mdlist;
      }
    },

    selectCourseList() {
      let param = new Map();
      param.set("pageNum", 1);
      param.set("pageSize", 200);
      param.set("status", 'ONLINE');
      let response = request('get', 'course/list', param);
      response.then(data => {
        let courseList = []
        data.data.data.forEach(course => {
          let temp = {
            id: course.id,
            name: course.name,
            mark: 1
          }
          courseList.push(temp)
        })
        this.courseList = courseList
      });
    },

    cancel() {
      this.$router.go(-1)
    },


    showCourseDialog() {
      let showCourseList = []
      for (let i = 0; i < this.courseList.length; i++) {
        if (this.courseList[i].mark == 1) {
          showCourseList.push(this.courseList[i])
        }
      }
      this.showCourseList = showCourseList
      this.courseDialogFormVisible=true
    },

    addCourse() {
      if (this.courseId == 0) {
        this.message("请选择标签！");
        return;
      }
      for (let i = 0; i < this.courseList.length; i++) {
        if (this.courseId == this.courseList[i].id) {
          this.courseList[i].mark = 0
          this.courseId = ''
          this.linkedCourseList.push(this.courseList[i])
          this.courseDialogFormVisible=false
          return;
        }
      }
    },

    removeCourse(courseId) {
      for (let i = 0; i < this.courseList.length; i++) {
        if (courseId == this.courseList[i].id) {
          this.courseList[i].mark = 1
          break;
        }
      }
      let linkedCourseList = []
      for (let i = 0; i < this.linkedCourseList.length; i++) {
        if (courseId == this.linkedCourseList[i].id) {
          continue;
        }
        linkedCourseList.push(this.linkedCourseList[i])
      }
      this.linkedCourseList = linkedCourseList
    },


    commit() {
      if (this.messageType == '') {
        this.message("消息类型不能为空！");
        return;
      }

      if (this.linkedCourseList == []) {
        this.message("发送范围不能为空！");
        return;
      }

      if (this.time == '') {
        this.message("发送时间不能为空！");
        return;
      }

      // if (this.content == '') {
      //   this.message("发送内容不能为空！")
      //   return;
      // }

      let linkedCourseIdList = []
      this.linkedCourseList.forEach(course => {
        linkedCourseIdList.push(course.id)
      })

      let param = {
        type: this.messageType,
        sendTime: this.sendTime,
        startTime: this.startTime,
        lessonName: this.lessonName,
        content: this.content,
        url: this.url,
        courseIdList: linkedCourseIdList
      }
      let response = request('post', 'message/create', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("创建成功！")
          this.$router.push({  //核心语句
            path:'/wx-message-manager',   //跳转的路径
          })
        } else {
          this.message("创建失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>