<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="commodity">

    <div class="commodity-list" v-for="commodity in commodityList" :key="commodity">
      <table class="commodity-content-table" @click="goToCommodityDetail(commodity.id)">
        <tr>
          <td class="commodity-name-td">{{commodity.name}}</td>
          <td class="commodity-price-td">￥{{commodity.displayPrice}}</td>
        </tr>
        <tr>
          <td colspan="2" class="commodity-create-time-td">{{commodity.createTime}}</td>
        </tr>
        <tr>
          <td colspan="2" class="commodity-course-title-td">包含课程</td>
        </tr>
        <tr v-for="course in commodity.relatedCourseList" :key="course">
          <td colspan="2" class="commodity-course-name-td">{{course.name}}</td>
        </tr>
      </table>
    </div>

    <div class="bottom-div">

    </div>
  </div>
</template>

<script>

import "../../../css/wx/commodity/commodityList.css";
 import store, {request, timeTransform} from "../../../store";
// import store from '../../store';

export default {
  name: 'CommodityList',
  components: {

  },
  data () {
    return {
      commodityList: []
    }
  },

  mounted() {
    this.listPayRecord()
  },

  methods: {
    listPayRecord() {
      let response = request('get', 'wx/commodity/list');
      response.then(data => {
        if (data.code == 200) {
          let commodityList = []
          data.data.data.forEach(commodity => {
            let tmp = {
              id: commodity.id,
              name: commodity.name,
              price: commodity.price,
              available: commodity.available,
              displayPrice: commodity.displayPrice,
              relatedCourseList: commodity.relatedCourseList,
              createTime: timeTransform(new Date(commodity.createTime), "YYYY-MM-DD HH:ii:ss")
            }
            commodityList.push(tmp)
          })
          this.commodityList = commodityList
        } else {
          this.message("查询失败！")
        }
      })
    },

    goToCommodityDetail(id) {
      if (store.state.userInfo.status == undefined || store.state.userInfo.status == 'UNREGISTERED') {
        this.goToRegister()
        return
      }
      this.$router.push({
        path: '/commodity-detail',
        query: {
          id: id
        }
      })
    },

    goToRegister() {
      this.$router.push({
        path: '/register',
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>
<style >


</style>