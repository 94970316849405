<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="plan-create-header-div">
      <span><strong>新建飞行训练任务</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="plan-create-content-div">
        <div class="plan-create-base-info">
          课时创建
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时名称:">
              <el-input style="width: 300px" v-model="name">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="所属课程:">
              <el-select style="width: 300px"
                         @change="courseChange"
                         v-model="courseSelect"
                         placeholder="请选择">
                <el-option
                    v-for="item in courseList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时序号:">
              <el-input style="width: 300px" v-model="lessonIndex">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课件地址:">
              <el-input style="width: 300px" v-model="coursewareUrl">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时音视频地址:">
              <el-input style="width: 300px" v-model="videoUrl">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="解读音视频地址:">
              <el-input style="width: 300px" v-model="interpretVideoUrl">
              </el-input>
            </el-form-item>
          </el-form>
          <UploadVideo :limit="9" :fileList="fileList"
                       @handleRemove="handleRemove"
                       @onSuccessFiles="onSuccessImgFiles" />
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="解读文字描述:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="contents"/>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时训练提示:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="trainingNotice"/>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时训练模板:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="trainingConfig"/>
            </el-form-item>
          </el-form>
        </div>

        <div class="plan-create-pagination-div">
          <el-button type="primary" @click="commit()">创建</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/course/courseCreate.css";
import store, {request} from "../../store";
import UploadVideo from '../../components/upload/UploadVideo'

export default {
  data() {
    return {
      name: '',
      lessonIndex: '',
      coursewareUrl: '',
      videoUrl: '',
      interpretVideoUrl: '',
      contents: '',
      courseSelect: '',
      courseList: [],
      trainingNotice: '',
      trainingConfig: '',
      fileList: [],
    }
  },

  components: {
    UploadVideo
  },

  mounted() {
    this.initSelectFilterdata();
  },

  methods: {

    // 第一个页面
    // 初始化下拉框数据
    initSelectFilterdata() {
      store.state.uploadDisabled = false;
      let response = request('get', 'lesson/filterdata', null);
      response.then(data => {
        let dataList = data.data
        this.courseList = this.initGroupType(dataList, 'COURSE_DATA', true)
      });
    },

    initGroupType(dataList, typeName, defaultEmpty) {
      for (let i = 0; i < dataList.length; i++) {
        let meta = dataList[i]
        if (meta.name != typeName) {
          continue
        }
        let all = {};
        all.id = '';
        all.name = '全部';
        let mdlist = [];
        if (!defaultEmpty) {
          mdlist.push(all)
        }
        meta.dataList.forEach(m => {
          let md = {};
          md.name = m.name;
          md.id = m.id;
          md.type = m.expand
          mdlist.push(md);
        })
        return mdlist;
      }
    },

    courseChange() {
      this.courseList.forEach(course => {
        if (course.id == this.courseSelect) {
          store.state.courseType = course.type
        }
      })
    },

    onSuccessImgFiles(files) {
      this.fileList = files
      this.videoUrl = files[0].url
      if (files.length > 1) {
        this.interpretVideoUrl = files[1].url
      }
      this.message("上传成功")
    },

    handleRemove(files) {
      if (files.length > 0) {
        this.videoUrl = files[0].url
        this.interpretVideoUrl = ''
      } else {
        this.videoUrl = ''
        this.interpretVideoUrl = ''
      }
      this.fileList = files
    },

    cancel() {
      this.$router.go(-1)
    },

    commit() {
      if (this.name == '') {
        this.message("课程名称不能为空！");
        return;
      }

      if (this.img == '') {
        this.message("课程封面图片不能为空！");
        return;
      }

      if (this.lessonIndex == '') {
        this.message("课程节数不能为空！");
        return;
      }

      if (this.videoUrl == '') {
        this.message("课程音频不能为空！");
        return;
      }

      if (this.price == '') {
        this.message("课程价格不能为空！");
        return;
      }

      if (this.desc == '') {
        this.message("课程描述不能为空！")
        return;
      }

      if (this.trainingConfig == '') {
        this.message("课程训练模板不能为空！")
        return;
      }

      let param = {
        name: this.name,
        index: this.lessonIndex,
        couresId: this.courseSelect,
        videoUrl: this.videoUrl,
        interpretVideoUrl: this.interpretVideoUrl,
        coursewareUrl: this.coursewareUrl,
        contents: this.contents,
        trainingNotice: this.trainingNotice,
        trainingConfig: this.trainingConfig
      }
      let response = request('post', 'lesson/create', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("创建成功！")
          this.$router.push({  //核心语句
            path:'/lesson-manager',   //跳转的路径
          })
        } else {
          this.message("创建失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>