<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

<!--  头部-->
  <div class="training">

<!--    课程选择-->
    <div class="training-lesson-space-div">
      {{this.courseName}}
    </div>

<!--    训练记录列表-->

    <div class="record-list-div" v-for="record in recordList" :key="record" @click="goToTrainingRecord(record)">

      <div >
        <div>
          <img class="record-user-info-head-portrait" :src="record.userHeadPortrait" />
        </div>
        <div class="record-lesson-info-div">
          <tr>
            <td class="record-lesson-info-td">{{record.courseName}}</td>
          </tr>
          <tr>
            <td class="record-lesson-info-td">{{record.lessonName}}</td>
          </tr>
          <tr>
            <td class="record-lesson-info-td">{{record.createTime}}</td>
          </tr>
        </div>
      </div>

    </div>


    <div class="bottom-div">
    </div>

  </div>
</template>

<script>

import "../../../css/wx/train/trainingLessonList.css";
import {request, timeTransform} from "../../../store";

export default {

  data () {
    return {
      courseId: this.$route.query.courseId,
      courseName: this.$route.query.courseName,
      recordList: [],
    }
  },

  mounted() {
    this.getRecordList()
  },

  methods: {

    getRecordList() {
      let response = request('get', 'wx/training/list/course?courseId=' + this.courseId, null );
      response.then(data => {
        if (data.code == 200) {
          this.courseList = []
          this.recordList = []
          let recordList = data.data;
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i]
            let recordData = {
              id: record.id,
              lessonId: record.lessonId,
              courseId: record.courseId,
              lessonName: record.lessonName,
              courseName: record.courseName,
              content: record.content,
              userNickname: record.userNickname,
              userHeadPortrait: record.userHeadPortrait,
              createTime: timeTransform(new Date(record.createTime), "YYYY-MM-DD HH:ii")
            }
            this.recordList.push(recordData)
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    goToTrainingRecord(record) {
      this.$router.push({
        path: '/training-show',
        query: {
          id: record.lessonId,
          title: record.lessonName,
          courseId: record.courseId,
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>