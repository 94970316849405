<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="noitce-update-header-div">
      <span><strong>留言信息</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">

      <div class="leave-message-content-div">
        <div class="comment-list-div" v-for="message in leaveMessageList" :key="message">
          <table class="comment-table" v-if="message.type == 'LEAVE_MESSAGE'">
            <tr>
              <td class="comment-user-head-portrait-td">
                <img class="comment-user-head-portrait" :src="message.headPortrait"/>
              </td>
              <td class="comment-user-nickname-td">{{message.nickname}}</td>
              <td class="comment-user-create-time-td">{{message.createTime}}</td>
            </tr>
            <tr>
              <td colspan="3" class="comment-user-content-td">
                <div class="leave-message-content">
                  {{message.content}}
                </div>
              </td>
            </tr>
          </table>

          <table class="comment-table" v-if="message.type == 'REPLAY_MESSAGE'">
            <tr>
              <td class="comment-user-create-time-right-td">{{message.createTime}}</td>
              <td class="comment-user-nickname-right-td">{{message.nickname}}</td>
              <td class="comment-user-head-portrait-right-td">
                <img class="comment-user-head-portrait" :src="message.headPortrait"/>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="comment-user-content-td">
                <div class="replay-content">
                  {{message.content}}
                </div>
              </td>
            </tr>
          </table>
        </div>


        <div class="el-pagination-div">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totle">
          </el-pagination>
        </div>

        <div class="replay-div">
          <tr>
            <td>
              <el-form :inline="true"  label-width="80px">
                <el-form-item label="回复:">
                  <el-input
                      type="textarea"
                      resize="none"
                      style="width: 370px"
                      :autosize="{ minRows: 6, maxRows: 20}"
                      v-model="replayContent"/>
                </el-form-item>
              </el-form>
            </td>
          </tr>
          <tr>
            <td>
              <el-button type="primary" @click="cancel()">取消</el-button>
              <el-button type="primary" @click="send()">提交</el-button>
            </td>
          </tr>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/user/leaveMessageManager.css";
import "../../css/wx/comment.css";
import store, {request, timeTransform} from "../../store";

export default {
  name: 'LeaveMessageManager',
  data() {
    return {
      userId: this.$route.query.id,
      replayContent: '',

      pageChange: false,
      pageNum: 1,
      pageSize: 10,
      totle: 0,
      leaveMessageList: [],
    }
  },

  components: {
  },

  mounted() {
    this.onSearch()
    store.state.courseType = 'DEFAULT'
  },

  methods: {

    onSearch() {
      let response = request('get', 'leave/message/list?userId=' + this.userId
          + "&pageNum=" + this.pageNum + "&pageSize=" + this.pageSize, null);
      response.then(data => {
        if (data.code == 200) {
          let commentList = []
          let recordList = data.data.data;
          if (recordList == null || recordList.length == 0) {
            return;
          }
          this.totle = data.data.count;
          recordList.forEach(message => {
            let temp = {
              id: message.id,
              nickname: message.nickname,
              headPortrait: message.headPortrait,
              content: message.content,
              type: message.type,
              createTime: timeTransform(new Date(message.createTime), "YYYY-MM-DD HH:ii:ss")
            }
            commentList.push(temp)
          })
          this.leaveMessageList = commentList;
        } else {
          this.message("查询失败！")
        }
      });
    },

    send() {
      if (this.replayContent == '') {
        this.message("请输入内容!")
        return
      }
      let param = {
        userId: this.userId,
        content: this.replayContent,
      }
      let response = request('post', 'leave/message/create', param);
      response.then(data => {
        if (data.code == 200) {
          this.onSearch()
          this.replayContent = ''
          this.message("回复成功！")
        } else {
          this.message("回复失败！")
        }
      });
    },

    handleRemove(files) {
      this.img = files[0].url
      this.fileList = files
    },

    cancel() {
      this.$router.back()
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.onSearch()
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.onSearch()
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>