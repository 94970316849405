<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="plan-create-header-div">
      <span><strong>修改课时信息</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="plan-create-content-div">
        <div class="plan-create-base-info">
          课时信息修改
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="资讯title:">
              <el-input style="width: 300px" v-model="title">
              </el-input>
            </el-form-item>
          </el-form>

          <el-form :inline="true"  label-width="150px">
            <el-form-item label="资讯序号:">
              <el-input style="width: 300px" v-model="index">
              </el-input>
            </el-form-item>
          </el-form>

        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="图片:">
              <el-input style="width: 300px" v-model="img">
              </el-input>
            </el-form-item>
          </el-form>

          <UploadVideo :limit="9" :fileList="fileList"
                       @handleRemove="handleRemove"
                       @onSuccessFiles="onSuccessImgFiles" />
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="资讯内容:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="content"/>
            </el-form-item>
          </el-form>
        </div>

        <div class="plan-create-pagination-div">
          <el-button type="primary" @click="update()">修改</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/news/newsUpdate.css";
import store, {request} from "../../store";
import UploadVideo from '../../components/upload/UploadVideo'

export default {
  data() {
    return {
      id: this.$route.query.id,
      title: '',
      index: '',
      img: '',
      content: '',
      fileList: [],
    }
  },

  components: {
    UploadVideo
  },

  mounted() {
    this.initDate()
    store.state.courseType = 'DEFAULT'
  },

  methods: {

    initDate() {
      let response = request('get', 'news/get?id=' + this.id, null);
      response.then(data => {
        if (data.code == 200) {
          this.title = data.data.title
          this.index = data.data.index
          this.img = data.data.img,
          this.content = data.data.content
          if (data.data.img != '' && data.data.img != undefined) {
            this.fileList = [{name: 'img', url: data.data.img}]
          }
        } else {
          this.message("查询失败！")
        }
      });
    },

    onSuccessImgFiles(files) {
      this.fileList = files
      this.img = files[0].url
      this.message("上传成功")
    },

    handleRemove(files) {
      this.img = files[0].url
      this.fileList = files
    },

    cancel() {
      this.$router.go(-1)
    },

    update() {
      if (this.title == '') {
        this.message("资讯title不能为空！");
        return;
      }

      if (this.img == '') {
        this.message("资讯图片不能为空！");
        return;
      }

      if (this.content == '') {
        this.message("资讯内容不能为空！");
        return;
      }

      if (this.index == '') {
        this.message("资讯序号不能为空！");
        return;
      }

      let param = {
        id: this.id,
        title: this.title,
        index: this.index,
        img: this.img,
        content: this.content,
      }
      console.log(param)
      let response = request('post', 'news/update', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("修改成功！")
          this.$router.push({  //核心语句
            path:'/news-manager',   //跳转的路径
          })
        } else {
          this.message("修改失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>