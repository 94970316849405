<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="monthly">
    <div class="monthly-introduction">
      <tr>
        <td class="course-introduction">{{this.title}}</td>
      </tr>
      <div class="author-img-div">
        <img class="author-img" :src="masterHeadPortrait"/>
      </div>
      <div class="author-name-div">
        {{masterName}}
      </div>
      <tr>
        <td class="course-introduction-content">
          <div class="monthly-comment-list" v-for="content in contentList" :key="content">
            <p class="paragraph-format">
              &emsp;&emsp;{{content.content}}
            </p>
          </div>
          <p class="paragraph-format">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{this.createTime}}</p>
        </td>
      </tr>
    </div>

    <div class="bottom-div">

    </div>
  </div>
</template>

<script>

import "../../css/wx/monthlyLesson.css";
import "../../css/wx/contents.css";
import "../../css/wx/common.css";
import store, { request, timeTransform} from "../../store";

export default {
  name: 'NewsDetail',
  components: {
  },
  data () {
    return {
      masterName: store.state.masterName,
      masterHeadPortrait: store.state.masterHeadPortrait,
      id: this.$route.query.id,
      title: '',
      contentList: '',
      createTime: ''
    }
  },

  mounted() {
    this.initLesson();
    this.initTrainingRecord()
    Window.scroll(0, 0)
  },

  methods: {
    mescrollInit (mescroll) {
      this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },

    initLesson() {
      let response = request('get', 'wx/news/get?id=' + this.id, null);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null) {
            this.id = data.data.id;
            this.title = data.data.title;
            this.contentList = data.data.contentList
            this.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii")
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    refreshPage() {
      this.initLesson();
      this.mescroll.endSuccess()
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }
  }

}
</script>
<style >
.time-instruction{
  white-space: pre-line;
}
</style>