<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\CourseManager.vue
-->
<template>
  <div class="login">
    <div>
      <div class="wx-login-top">心理训练管理系统</div>
    </div>
    <div class="wx-login-body">
      <div class="wx-login-div">
        <table class="wx-login-table">
          <tr>
            <td class="wx-login-title-td">用户名：</td>
            <td class="wx-login-input-td">
                <el-input style="width: 180px; height: 50px" v-model="userName">
                </el-input>
            </td>
          </tr>
          <tr>
            <td class="wx-login-title-td">登陆密码：</td>
            <td class="wx-login-input-td">
                <el-input type="password" style="width: 180px; height: 50px" v-model="password">
                </el-input>
            </td>
          </tr>
          <tr>
            <td class="wx-login-title-td"></td>
            <td>
              <el-button type="primary" @click="clear">清空</el-button>
              <el-button type="primary" @click="login">登陆</el-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import "../css/wxLogin.css";
import store, {request} from "../store";

export default {
  data() {
    return {
      userName: '',
      password: ''
    }
  },

  mounted() {
  },

  methods: {
    login() {
      if (this.userName == '') {
        this.message("用户名不能为空！")
        return
      }
      if (this.password == '') {
        this.message("密码不能为空！")
        return
      }
      let param = {
        userName: this.userName,
        password: this.password
      }
      let response = request('post', 'user/login/manager', param);
      response.then(data => {
        if (data.code == 200) {
          store.state.userInfo = data.data
          this.$router.push({
            path: '/wxIndex',
          })
        } else {
          this.message("登录失败！")
        }
      })
    },

    clear() {
      this.userName = ''
      this.password = ''
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }
  }
}
</script>
