<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="pay">

    <div class="payment-list" v-for="payment in paymentList" :key="payment">
      <div class="payment-amount">
        ￥{{payment.displayPrice}}
      </div>
      <div class="payment-content">
        <table class="payment-content-table">
          <tr>
            <td class="payment-comtent-table-title-td">当前状态：</td>
            <td class="payment-comtent-table-content-td">{{payment.status}}</td>
          </tr>
          <tr>
            <td class="payment-comtent-table-title-td">付款方式：</td>
            <td class="payment-comtent-table-content-td">{{payment.paymentMethod}}</td>
          </tr>
          <tr>
            <td class="payment-comtent-table-title-td">商品名称：</td>
            <td class="payment-comtent-table-content-td">{{payment.commodity}}</td>
          </tr>
          <tr>
            <td class="payment-comtent-table-title-td">商户全称：</td>
            <td class="payment-comtent-table-content-td">{{payment.merchantName}}</td>
          </tr>
          <tr>
            <td class="payment-comtent-table-title-td">支付时间：</td>
            <td class="payment-comtent-table-content-td">{{payment.createTime}}</td>
          </tr>
          <tr>
            <td class="payment-comtent-table-title-td">交易单号：</td>
            <td class="payment-comtent-table-content-td">{{payment.orderId}}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="bottom-div">

    </div>
  </div>
</template>

<script>

import "../../../css/wx/commodity/paymentRecord.css";
 import {request, timeTransform} from "../../../store";
// import store from '../../store';

export default {
  name: 'PayForCourse',
  components: {

  },
  data () {
    return {
      paymentList: []
    }
  },

  mounted() {
    this.listPayRecord()
  },

  methods: {
    listPayRecord() {
      let response = request('get', 'wx/payment/list');
      response.then(data => {
        if (data.code == 200) {
          let paymentList = []
          data.data.forEach(payment => {
            let tmp = {
              status: payment.status,
              orderId: payment.transactionId,
              paymentMethod: payment.paymentMethod,
              merchantName: payment.merchantName,
              amounts: payment.amounts,
              displayPrice: payment.displayPrice,
              commodity: payment.commodity,
              createTime: timeTransform(new Date(payment.createTime), "YYYY-MM-DD HH:ii:ss")
            }
            paymentList.push(tmp)
          })
          this.paymentList = paymentList
        } else {
          this.message("查询失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>
<style >


</style>