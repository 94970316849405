<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="hundred">
    <div class="monthly-introduction">
      <tr>
        <td class="course-introduction">{{this.title}}</td>
      </tr>
      <div class="author-img-div">
        <img class="author-img" :src="masterHeadPortrait"/>
      </div>
      <div class="author-name-div">
        {{masterName}}
      </div>
      <tr>
        <td class="course-introduction-content">
          <div class="monthly-comment-list" v-for="content in contentList" :key="content">
            <p>
              &emsp;&emsp;{{content.content}}
            </p>
          </div>
          <p>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{this.createTime}}</p>
        </td>
      </tr>
    </div>

    <!-- 视频课程 -->
    <div class="course-video-div">
      <tr>
        <td class="course-video-introduction">视频课程</td>
      </tr>
      <tr>
        <td class="course-video-space-td"></td>
      </tr>
      <div class="lesson-video-div" v-for="item in playerOptions.sources" :key="item">
        <div class="hundred-lesson-video-div">
          <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"/>
        </div>
      </div>
    </div>

    <!-- 课程留言 -->
    <div class="course-operation-div" v-if="!isTrained">
      <el-button type="primary" @click="goToTrain()">开始训练</el-button>
    </div>
    <div class="course-operation-div" v-if="isTrained">
      <el-button type="primary" @click="goToTrainingRecord()">训练记录</el-button>
    </div>

    <!-- 评论区 -->
    <div class="monthly-record-comment-split-line"></div>
    <div class="monthly-comment">
      <tr>
        <td class="course-introduction">精彩评论</td>
      </tr>
      <div class="monthly-comment-list" v-for="record in trainingRecordList" :key="record">
        <div class="record-user-head-portrait-div">
          <img class="record-user-head-portrait" :src="record.userHeadPortrait"/>
        </div>
        <div class="record-user-info-div">
          <tr><td>{{record.userNickname}}</td></tr>
          <tr><td>{{record.createTime}}</td></tr>
        </div>
        <div class="monthly-record-comment-option">
          <!--          <input type="button" v-model="replyContent"/><img class="monthly-record-comment-option-icon" src="../../assets/img/icon/reply.png" @click="reply(record.id)"/>-->
        </div>
        <div class="monthly-record-comment">
          <div class="monthly-record-comment-content" v-for="content in record.contents" :key="content">
            <div class="monthly-record-comment-content-title">{{content.title}}</div>
            <div>{{content.content}}</div>
          </div>
        </div>
        <div class="monthly-record-comment-reply" v-for="sub in trainingRecordList.subRecordList" :key="sub">
          <div>
            {{sub.userNickname}}
          </div>
          <div>
            {{sub.content}}
          </div>
        </div>
        <div class="monthly-record-comment-split-line" v-if="record.line"></div>
      </div>
    </div>

    <div class="bottom-div">

    </div>
  </div>
</template>

<script>

import "../../css/wx/hundredDaysLesson.css";
import "../../css/wx/contents.css";
import "../../css/wx/common.css";
import "../../css/wx/video.css";
import store, {request, timeTransform} from "../../store";
import Vue from "vue";
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);

export default {
  name: 'WxIndex',
  components: {

  },
  data () {
    return {
      masterName: store.state.masterName,
      masterHeadPortrait: store.state.masterHeadPortrait,
      lessonId: this.$route.query.id,
      id: '',
      courseId: '',
      title: '',
      createTime: '',
      videoUrl: '',
      contentList: [],
      templateList: [],
      isTrained: false,
      trainingRecordList: [],
      replyContent: '',

      playerOptions: {

        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "false", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。

        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
          enterOnRotate: true,
        },

        activeIndex:'0', //右侧课程选择切换背景色
        sources: []
      },
    }
  },

  mounted() {
    this.initLesson();
    this.initTrainingRecord()
    Window.scroll(0, 0)
  },

  methods: {
    initLesson() {
      let response = request('get', 'wx/lesson/get?id=' + this.lessonId, null);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null) {
            this.id = data.data.id;
            this.title = data.data.name;
            let source = {
              type:"video/mp4",
              src: data.data.videoUrl
            }
            this.playerOptions.sources.push(source)
            this.desc = data.data.desc;
            this.courseId = data.data.couresId;
            this.contentList = data.data.contentList
            this.templateList = data.data.templateList
            this.isTrained = data.data.isTrained
            this.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii")
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    initTrainingRecord() {
      let response = request('get', 'wx/training/list?lessonId=' + this.lessonId, null);
      response.then(data => {
        if (data.code == 200) {
          let list = [];
          let recordList = data.data;
          if (recordList == null || recordList.length == 0) {
            return;
          }
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i];
            let subRecordList = []
            if (record.subRecord != undefined && record.subRecord != null) {
              record.subRecord.forEach(sub => {
                let subRecord = {
                  id: sub.id,
                  userNickname: sub.userNickname,
                  content: sub.content,
                  userHeadPortrait: sub.userHeadPortrait,
                  createTime: timeTransform(new Date(sub.createTime), "YYYY-MM-DD HH:ii"),
                }
                subRecordList.push(subRecord)
              })
            }
            let contentList = []
            let trcList = record.trainingRecordContentList
            for (let j = 0; j < trcList.length; j++) {
              let content = trcList[j]
              let tmp = {
                title: content.title,
                content: content.content
              }
              contentList.push(tmp)
            }
            let tmp = {
              id: record.id,
              contents: contentList,
              userNickname: record.userNickname,
              userHeadPortrait: record.userHeadPortrait,
              createTime: timeTransform(new Date(record.createTime), "YYYY-MM-DD HH:ii"),
              imgs: record.imgs,
              subRecordList: subRecordList,
              line: true
            }
            list.push(tmp)
          }
          list[list.length-1].line = false
          this.trainingRecordList = list
        } else {
          this.message("查询失败！")
        }
      })
    },

    goToTrain() {
      this.$router.push({
        path: '/monthly-training',
        query: {
          id: this.id,
          title: this.title,
          courseId: this.courseId,
          templateList: this.templateList
        }
      })
    },

    goToTrainingRecord() {
      this.$router.push({
        path: '/training-show',
        query: {
          id: this.id,
          title: this.title,
          courseId: this.courseId,
          templateList: this.templateList
        }
      })
    },

    reply(id) {
      console.info(id)
    }
  }

}
</script>
<style >


</style>