<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="commodity-update-header-div">
      <span><strong>修改商品</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="commodity-update-content-div">
        <div class="commodity-update-base-info">
          商品修改
        </div>
        <div class="commodity-update-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="商品名称:">
              <el-input style="width: 300px" v-model="name">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="商品价格:">
              <el-input style="width: 300px" v-model="price">
              </el-input>
            </el-form-item>
            <el-form :inline="true"  label-width="150px">
              <el-form-item label="商品描述:">
                <el-input
                    type="textarea"
                    resize="none"
                    style="width: 300px"
                    :autosize="{ minRows: 6, maxRows: 20}"
                    v-model="desc"/>
              </el-form-item>
            </el-form>
          </el-form>
        </div>
        <div class="case-info">
          关联课程
        </div>
        <div class="course-info-div">
          <table class="course-info-table">
            <tr>
              <td class="course-info-td">课程</td>
              <td class="course-info-td">操作</td>
            </tr>
            <tr v-for="course in linkedCourseList" :key="course">
              <td class="course-info-option">{{course.name}}</td>
              <td class="course-info-option">
                <el-button @click="removeCourse(course.id)">删除</el-button>
              </td>
            </tr>
          </table>
          <div class="add-label-button">
            <el-button type="primary" @click="showCourseDialog()" >
              +添加课程
            </el-button>
          </div>
        </div>


        <div class="commodity-update-pagination-div">
          <el-button type="primary" @click="commit()">修改</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>

        <el-dialog title="场景标签" style="color: #EDB244; font-size: 40px;" :visible.sync="courseDialogFormVisible" width="710px">
          <div id="statistical-data-table" class="data-table-div">
            <el-select v-model="courseId"
                       aria-label="课程:"
                       placeholder="请选择">
              <el-option
                  v-for="item in showCourseList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div slot="footer">
            <el-button @click="addCourse()">添加</el-button>
            <el-button @click="courseDialogFormVisible=false">取消</el-button>
          </div>
        </el-dialog>

      </div>
    </div>

  </div>
</template>

<script>

import "../../css/commodity/commodityUpdate.css";
import {request} from "../../store";

export default {
  data() {
    return {
      id: this.$route.query.id,
      name: '',
      price: '',
      desc: '',
      courseList: [],
      linkedCourseList: [],
      showCourseList: [],
      courseId: '',
      courseIdList: [],
      courseDialogFormVisible: false
    }
  },

  components: {
  },

  mounted() {
    this.initSelectFilterdata();
  },

  methods: {

    initCommodity() {
      let response = request('get', 'commodity/get?id=' + this.id, null);
      response.then(data => {
        if (data.code == 200) {
          this.name = data.data.name
          this.price = data.data.price
          this.desc = data.data.desc
          let linkedCourseList = []
          data.data.relatedCourseList.forEach(course => {
            let temp = {
              id: course.id,
              name: course.name
            }
            for (let i = 0; i < this.courseList.length; i++) {
              if (this.courseList[i].id == course.id) {
                this.courseList[i].mark = 0
              }
            }
            linkedCourseList.push(temp)
          })
          this.linkedCourseList = linkedCourseList
        }
      });
    },

    // 第一个页面
    // 初始化下拉框数据
    initSelectFilterdata() {
      let param = new Map();
      param.set("pageNum", 1);
      param.set("pageSize", 200);
      param.set("status", 'ONLINE');
      let response = request('get', 'course/list', param);
      response.then(data => {
        let courseList = []
        data.data.data.forEach(course => {
          let temp = {
            id: course.id,
            name: course.name,
            mark: 1
          }
          courseList.push(temp)
        })
        this.courseList = courseList
        this.initCommodity()
      });
    },

    showCourseDialog() {
      let showCourseList = []
      for (let i = 0; i < this.courseList.length; i++) {
        if (this.courseList[i].mark == 1) {
          showCourseList.push(this.courseList[i])
        }
      }
      this.showCourseList = showCourseList
      this.courseDialogFormVisible=true
    },

    addCourse() {
      if (this.courseId == 0) {
        this.message("请选择标签！");
        return;
      }
      for (let i = 0; i < this.courseList.length; i++) {
        if (this.courseId == this.courseList[i].id) {
          this.courseList[i].mark = 0
          this.courseId = ''
          this.linkedCourseList.push(this.courseList[i])
          this.courseDialogFormVisible=false
          return;
        }
      }
    },

    removeCourse(courseId) {
      for (let i = 0; i < this.courseList.length; i++) {
        if (courseId == this.courseList[i].id) {
          this.courseList[i].mark = 1
          break;
        }
      }
      let linkedCourseList = []
      for (let i = 0; i < this.linkedCourseList.length; i++) {
        if (courseId == this.linkedCourseList[i].id) {
          continue;
        }
        linkedCourseList.push(this.linkedCourseList[i])
      }
      this.linkedCourseList = linkedCourseList
    },

    cancel() {
      this.$router.go(-1)
    },

    commit() {
      if (this.name == '') {
        this.message("商品名称不能为空！");
        return;
      }
      if (this.price == '') {
        this.message("商品价格不能为空！");
        return;
      }
      if (this.desc == '') {
        this.message("商品描述不能为空！")
        return;
      }
      if (this.linkedCourseList.length <= 0) {
        this.message("关联商品不能为空！")
        return;
      }
      let linkedCourseIdList = []
      this.linkedCourseList.forEach(course => {
        linkedCourseIdList.push(course.id)
      })
      let param = {
        id: this.id,
        name: this.name,
        price: this.price,
        desc: this.desc,
        relatedCourses: linkedCourseIdList
      }
      let response = request('post', 'commodity/update', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("修改成功！")
          this.$router.push({  //核心语句
            path:'/commodity-manager',   //跳转的路径
          })
        } else {
          this.message("修改失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>