<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="monthly">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
    <div class="monthly-introduction">
      <tr>
        <td class="course-introduction">{{this.title}}</td>
      </tr>
      <div class="author-img-div">
        <img class="author-img" :src="masterHeadPortrait"/>
      </div>
      <div class="author-name-div">
        {{masterName}}
      </div>
      <tr>
        <td class="course-introduction-content">
          <div class="monthly-comment-list" v-for="content in contentList" :key="content">
            <p class="paragraph-format">
              &emsp;&emsp;{{content.content}}
            </p>
          </div>
          <p class="paragraph-format">&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{this.createTime}}</p>
        </td>
      </tr>
    </div>

  <!-- 音频课程 -->
    <div class="course-video-div">
      <tr>
        <td class="course-video-introduction">音频课程</td>
      </tr>
      <tr>
        <td class="course-video-space-td"></td>
      </tr>
      <div class="lesson-video-div">
        <div class="hundred-lesson-video-div">
          <audio
              id="video"
              :src="videoUrl"
              controls="controls"
              @play="videoOnPlay"
              ref="audio">
          </audio>
        </div>
      </div>
    </div>

    <!-- 音频解读 -->
    <div class="course-video-div">
      <tr>
        <td class="course-video-introduction">音频解读</td>
      </tr>
      <tr>
        <td class="course-video-space-td"></td>
      </tr>
      <div class="lesson-video-div">
        <div class="hundred-lesson-video-div">
          <audio
              id="interpretVideo"
              :src="interpretVideoUrl"
              controls="controls"
              @play="interpretVideoOnPlay"
              ref="audio">
          </audio>
        </div>
      </div>
    </div>

    <!-- 课程留言 -->
    <div class="course-operation-div" v-if="!isTrained">
      <el-button type="primary" @click="goToTrain()">开始训练</el-button>
    </div>
    <div class="course-operation-div" v-if="isTrained">
      <el-button type="primary" @click="goToTrainingRecord()">训练记录</el-button>
    </div>

    <!-- 评论区 -->
    <div class="monthly-record-comment-split-line"></div>
    <div class="monthly-comment">
      <tr>
        <td class="course-introduction">精彩评论</td>
      </tr>
        <div class="monthly-comment-list" v-for="record in trainingRecordList" :key="record">
          <div class="record-user-head-portrait-div">
            <img class="record-user-head-portrait" :src="record.userHeadPortrait"/>
          </div>
          <div class="record-user-info-div">
            <tr><td>{{record.userNickname}}</td></tr>
            <tr><td>{{record.createTime}}</td></tr>
          </div>
          <div class="monthly-record-comment-option">
  <!--          <input type="button" v-model="replyContent"/><img class="monthly-record-comment-option-icon" src="../../assets/img/icon/reply.png" @click="reply(record.id)"/>-->
          </div>
          <div class="monthly-record-comment">
            <div class="monthly-record-comment-content" v-for="content in record.contents" :key="content">
              <div class="monthly-record-comment-content-title">{{content.title}}</div>
              <div>{{content.content}}</div>
            </div>
          </div>
          <div class="monthly-record-comment-reply" v-for="sub in trainingRecordList.subRecordList" :key="sub">
            <div>
              {{sub.userNickname}}
            </div>
            <div>
              {{sub.content}}
            </div>
          </div>
          <div class="monthly-record-comment-split-line" v-if="record.line"></div>
        </div>

    </div>

    <mt-tabbar fixed class="foot" v-if="isTrained">
      <el-input placeholder="请输入信息"
                @focus="add"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 20}"
                v-model="content">
      </el-input>
      &nbsp;
      <el-button type="warning" @click="send">发送</el-button>
    </mt-tabbar>

    <div class="bottom-div">

    </div>
    </mescroll-vue>
  </div>
</template>

<script>

import "../../css/wx/monthlyLesson.css";
import "../../css/wx/contents.css";
import "../../css/wx/common.css";
import MescrollVue from 'mescroll.js/mescroll.vue'
import store, { request, timeTransform} from "../../store";

export default {
  name: 'MonthlyLesson',
  components: {
    MescrollVue
  },
  data () {
    return {
      masterName: store.state.masterName,
      masterHeadPortrait: store.state.masterHeadPortrait,
      lessonId: this.$route.query.id,
      id: '',
      courseId: '',
      title: '',
      createTime: '',
      videoUrl: '',
      interpretVideoUrl: '',
      contentList: [],
      templateList: [],
      isTrained: false,
      trainingRecordList: [],
      replyContent: '',
      content: '',

      mescrollDown:{
        callback: this.refreshPage,
      },
      mescrollUp: {
        auto: true, // 是否在初始化时以上拉加载的方式自动加载第一页数据; 默认false
        page: {
          num: 0,
          size: 10
        },
        callback: this.loadData,
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
      },
      mescroll: ''
    }
  },

  mounted() {
    this.initLesson();
    // this.initTrainingRecord()
    Window.scroll(0, 0)
  },

  methods: {
    mescrollInit (mescroll) {
      this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },

    initLesson() {
      let response = request('get', 'wx/lesson/get?id=' + this.lessonId, null);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null) {
            this.id = data.data.id;
            this.title = data.data.name;
            this.videoUrl = data.data.videoUrl;
            this.interpretVideoUrl = data.data.interpretVideoUrl;
            this.courseId = data.data.couresId;
            this.contentList = data.data.contentList
            this.templateList = data.data.templateList
            this.noticeList = data.data.noticeList
            this.isTrained = data.data.isTrained
            this.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii")
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    async refreshPage() {
      this.mescroll.endSuccess()
    },

    loadData(page) {
      // 联网加载数据
      this.initTrainingRecord(page.num, page.size, (curPageData) => {
        // 添加列表数据
        this.trainingRecordList = this.trainingRecordList.concat(curPageData)
        // 数据渲染成功后,隐藏下拉刷新的状态
        this.$nextTick(() => {
          this.mescroll.endSuccess(curPageData.length)
        })
      }, () => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        this.mescroll.endErr()
      })
    },

    initTrainingRecord(pageNum, pageSize, successCallback, errorCallback) {
      let url = 'wx/training/list?lessonId=' + this.lessonId + "&status=" + 'PUBLIC' + "&pageNum=" + pageNum + "&pageSize=" + pageSize
      let response = request('get', url, null);
      response.then(data => {
        if (data.code == 200) {
          let list = [];
          let recordList = data.data.data;
          if (recordList == null || recordList.length == 0) {
            successCallback && successCallback(list)
            return;
          }

          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i];
            let subRecordList = []
            if (record.subRecord != undefined && record.subRecord != null) {
              record.subRecord.forEach(sub => {
                let subRecord = {
                  id: sub.id,
                  userNickname: sub.userNickname,
                  content: sub.content,
                  userHeadPortrait: sub.userHeadPortrait,
                  createTime: timeTransform(new Date(sub.createTime), "YYYY-MM-DD HH:ii"),
                }
                subRecordList.push(subRecord)
              })
            }
            let contentList = []
            let trcList = record.trainingRecordContentList
            for (let j = 0; j < trcList.length; j++) {
              let content = trcList[j]
              let tmp = {
                title: content.title,
                content: content.content
              }
              contentList.push(tmp)
            }
            let tmp = {
              id: record.id,
              contents: contentList,
              userNickname: record.userNickname,
              userHeadPortrait: record.userHeadPortrait,
              createTime: timeTransform(new Date(record.createTime), "YYYY-MM-DD HH:ii"),
              imgs: record.imgs,
              subRecordList: subRecordList,
              line: true
            }
            list.push(tmp)
          }
          list[list.length-1].line = false
          // this.trainingRecordList = list
          this.mescroll.endSuccess(list.length)
          successCallback && successCallback(list)
        } else {
          this.message("查询失败！")
          errorCallback && errorCallback()
        }
      })
    },

    videoOnPlay() {
      let audio = document.getElementById("interpretVideo")
      if (!audio.paused) {
        audio.pause()
      }
    },

    interpretVideoOnPlay() {
      let audio = document.getElementById("video")
      if (!audio.paused) {
        audio.pause()
      }
    },

    goToTrain() {
      this.$router.push({
        path: '/monthly-training',
        query: {
          id: this.id,
          title: this.title,
          courseId: this.courseId,
          noticeList: this.noticeList,
          templateList: this.templateList
        }
      })
    },

    goToTrainingRecord() {
      this.$router.push({
        path: '/training-show',
        query: {
          id: this.id,
          title: this.title,
          courseId: this.courseId,
          noticeList: this.noticeList,
          templateList: this.templateList
        }
      })
    },

    add() {
      this.$nextTick(function () {
        document.documentElement.scrollTop = 1000000;
      });
      setTimeout(() => {
        document.documentElement.scrollTop = 10000;
      }, 100);
    },

    send() {
      if (this.content == '') {
        this.message("评论内容不能为空！")
        return;
      }
      let comments = [{
        title: '',
        content: this.content
      }]

      let param = {
        courseId: this.courseId,
        lessonId: this.lessonId,
        content: comments,
        type: 'COMMENT',
        status: 'PUBLIC',
      }
      let response = request('post', 'wx/training/create', param);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null && data.data != undefined && data.data) {
            this.message("评论成功！")
            let currentComment = {
              contents: [{"title": "", "content": this.content}],
              userNickname: store.state.userInfo.nickname,
              userHeadPortrait: store.state.userInfo.headPortrait,
              createTime: timeTransform(new Date(), "YYYY-MM-DD HH:ii"),
              imgs: [],
              subRecordList: [],
              line: true
            }
            let list = []
            list.push(currentComment)
            this.trainingRecordList.forEach(comment => {
              list.push(comment)
            })
            this.trainingRecordList = list
            this.content = ''
          }
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }
  }

}
</script>
<style >
.time-instruction{
  white-space: pre-line;
}
</style>