<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

<!--  头部-->
  <div class="training">

    <div class="training-course-space-div">
      <div id="SUPER_MASTER" class="training-course"></div>
    </div>

<!--    训练记录列表-->

    <div class="record-course-list-div" v-for="record in recordList" :key="record">
      <div class="record-course-info-div"  @click="goToLessonList(record.id, record.courseName)">
        {{record.courseName}}
      </div>
    </div>


    <div class="bottom-div">
    </div>

  </div>
</template>

<script>

import "../../../css/wx/train/trainingCourseList.css";
import {request, timeTransform} from "../../../store";

export default {

  data () {
    return {
      recordList: [],
    }
  },

  mounted() {
    this.getRecordList()
  },

  methods: {
    getRecordList() {
      let param = new Map();
      param.set("pageNum", 1);
      param.set("pageSize", 100);
      let response = request('get', 'wx/course/list', param);
      response.then(data => {
        if (data.code == 200) {
          this.courseList = []
          let recordList = data.data.data;
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i]
            let recordData = {
              id: record.id,
              courseName: record.name,
              createTime: timeTransform(new Date(record.createTime), "YYYY-MM-DD HH:ii")
            }
            if (record.paid) {
              this.recordList.push(recordData)
            }
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    goToLessonList(courseId, courseName) {
      this.$router.push({
        path: '/training-lesson-list',
        query: {
          courseId: courseId,
          courseName: courseName,
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>