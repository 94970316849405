<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>
  <div class="home">
    <el-container :style="{ height: clientHeight + 'px' }">
      <el-header>
<!--        <div style="height: 100px; width: 90px; padding-top: 20px; float:left; line-height: 80px;"><img style="width: 70px; height: 70px" src="image/logo.png"/></div>-->
        <div style="height: 100px; width: 30px; padding-top: 20px; float:left; line-height: 80px;"></div>
        <div style="height: 100px; width: 400px; line-height: 100px; float:left; padding-top: 5px; align-content: center;" >心理训练管理系统</div>
        <div style="height: 100px; width: auto; font-size: 24px; line-height: 100px; float:right; text-align: right; padding-top: 5px; align-content: center;" @click="logout">
          退出登录
        </div>
      </el-header>
      <el-container>
          <el-aside width="200px" style="padding-top: 40px">
            <el-menu   style="width: 201px; border-bottom-color: #F1F4F8; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/notice-manager" @click="change('noticeManager')">
                <div id="noticeManager" style="height: 60px; width: 201px;" @click="change('noticeManager')">
                  公告管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; border-bottom-color: #F1F4F8; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/auto-response-manager" @click="change('autoResponseManager')">
                <div id="autoResponseManager" style="height: 60px; width: 201px;" @click="change('autoResponseManager')">
                  自动回复管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; border-bottom-color: #F1F4F8; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/course-manager" @click="change('courseManager')">
                <div id="courseManager" style="height: 60px; width: 201px;" @click="change('courseManager')">
                  课程管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/lesson-manager"  @click="change('lessonManager')">
                <div id="lessonManager" style="height: 60px; width: 201px;" @click="change('lessonManager')">
                  课时管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/commodity-manager"  @click="change('commodityManager')">
                <div id="commodityManager" style="height: 60px; width: 201px;" @click="change('commodityManager')">
                  商品管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/payment-protocol-manager"  @click="change('paymentProtocolManager')">
                <div id="paymentProtocolManager" style="height: 60px; width: 201px;" @click="change('paymentProtocolManager')">
                  协议管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/news-manager"  @click="change('newsManager')">
                <div id="newsManager" style="height: 60px; width: 201px;" @click="change('newsManager')">
                  资讯管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/user-manager"  @click="change('userManager')">
                <div id="userManager" style="height: 60px; width: 201px;" @click="change('userManager')">
                  用户管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu   style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/train-manager"  @click="change('trainManager')">
                <div id="trainManager" style="height: 60px; width: 201px;" @click="change('trainManager')">
                  训练管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu  style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/pay-manager"  @click="change('payManager')">
                <div id="payManager" style="height: 60px; width: 201px;" @click="change('payManager')">
                  支付记录管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu  style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/comment-manager"  @click="change('commentManager')">
                <div id="commentManager" style="height: 60px; width: 201px;" @click="change('commentManager')">
                  评论管理
                </div>
              </router-link>
            </el-menu>
            <div style="height: 1px; width: 201px; background-color: #F1F4F8"></div>
            <el-menu  style="width: 201px; background-color: #2D333D" :default-active="menuIndex">
              <router-link class="router-link-class" to="/wx-message-manager"  @click="change('wxMessageManager')">
                <div id="wxMessageManager" style="height: 60px; width: 201px;" @click="change('wxMessageManager')">
                  微信消息管理
                </div>
              </router-link>
            </el-menu>
          </el-aside>
          <el-main>
              <router-view></router-view>
          </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>

import {request} from "../store";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
  },
  data () {
      return{
        menuIndex:'1',
        clientHeight: document.documentElement.clientHeight,
      }
  },

  mounted() {
    document.getElementById("noticeManager").click();
  },

  methods: {

    change(id) {
      let menuList = [];
      menuList.push('noticeManager');
      menuList.push('autoResponseManager');
      menuList.push('courseManager');
      menuList.push('lessonManager');
      menuList.push('commodityManager');
      menuList.push('paymentProtocolManager');
      menuList.push('newsManager');
      menuList.push('userManager');
      menuList.push('trainManager');
      menuList.push('payManager');
      menuList.push('commentManager');
      menuList.push('wxMessageManager');
      for (let i = 0; i < menuList.length; i++) {
        let data = menuList[i];
        if (id == data) {
          let menu = document.getElementById(data);
          menu.style.backgroundColor= "#F7D347";
        } else {
          let menu = document.getElementById(data);
          menu.style.backgroundColor= "#2D333D";
        }
      }
    },

    logout() {
      request('get', 'logout', null);
      window.location.href = "http://ssosv.it.test.sankuai.com/sson/login?t=1637806654078&redirect_uri=http%3A%2F%2Fdata.cloud.dev.sankuai.com%2Fcallbackpage%3Fcurrent_url=http%3A%2F%2Fdata.cloud.dev.sankuai.com&client_id=1ddaebd312"
    }
  }

}
</script>
<style >
.el-container{
    height:100%;
    width:100%;
}
.el-header  {
  background-color: #343E49;
  color: #fff;
  text-align: left;
  line-height: 100px;
  align-content: center;
  font-size: 36px;
  min-height: 100px;
  max-height: 100px;
}
  
.el-aside {
  background-color: #2B333E;
  color: #333;
  text-align: center;
  line-height: 60px;
  font-size: 24px;
}

/*.span-class {*/
/*  */
/*}*/

.router-link {
  height: 100px;
  color: #ffffff;
}

.el-menu-item-class {
  height: 100px;
  background-color: #2B333E;
  color: #ffffff;
}

.router-link-class {
  color: #FFFFFF;
  text-decoration: none;
}

.el-main {
  background-color: #F1F4F8;
  color: #333;
  }
  
</style>