<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\CourseManager.vue
-->
<template>
  <div id='data-manager' style="padding: 10px 10px 10px; background-color: #F1F4F8">
    <div id="data-header" class="data-header-div">
      <span><strong>用户管理</strong></span>
    </div>
    <div id="data-serch" class="data-serch-div">
      <div id="data-serch-title" class="data-serch-title-div">筛选</div>
      <el-form :inline="true"   class="demo-form-inline1">
        <el-form-item label="状态:">
          <el-select v-model="statusSelect" placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="搜索关键字:">
          <el-input style="width: 240px" v-model="keyWord" placeholder="用户名">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="clear">清空选项</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div id="data-content" class="data-content-div">
      <div id="data-option" class="data-option-div">
        <div style="text-align: left; width: 50%; color: #333333; font-size: 18px; float:left">用户列表</div>
      </div>

      <div id="data-table" class="data-table-div">
        <el-table
            :data="tableData"
            border
            ref="select"
            :header-cell-style="{background:'#F0F4F8', 'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="id"
              width="80">
          </el-table-column>
          <el-table-column
              prop="wxid"
              label="微信opid">
          </el-table-column>
          <el-table-column
              prop="nickname"
              label="微信昵称">
          </el-table-column>
          <el-table-column
              prop=""
              #default="scope"
              label="头像">
            <el-image :src="scope.row.headPortrait" class="cource-img">
            </el-image>
          </el-table-column>
          <el-table-column
              prop="realName"
              label="真实姓名">
          </el-table-column>
          <el-table-column
              prop="phoneNum"
              label="手机号">
          </el-table-column>
          <el-table-column
              prop="type"
              label="类型"
              width="100">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态"
              width="100">
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="开始时间"
              width="200">
          </el-table-column>
          <el-table-column
              label="操作"
              width="300">
            <template slot-scope="scope">
              <el-button type="primary" v-if="scope.row.status == 'NORMAL'" @click="updateStatus(scope.row.id, 'DISABLE')">
                下线
              </el-button>
              <el-button type="primary" v-if="scope.row.status == 'DISABLE'" @click="updateStatus(scope.row.id, 'NORMAL')">
                上线
              </el-button>
              <el-button type="primary" @click="goToLeaveMessage(scope.row.id)">
                留言
                <if v-if="scope.row.order == '3'">
                  ✯
                </if>
              </el-button>
              <el-button type="primary" @click="selectPaymentRecord(scope.row.wxid)">
                付费记录
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
<!--      分页-->
      <div class="el-pagination-div">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totle">
        </el-pagination>
      </div>
    </div>

    <!--    数据上传弹窗-->
    <el-dialog title="数据上传" style="color: #EDB244; font-size: 40px" :visible.sync="showCourseDetails" width="710px">
      <div>
        <el-form label-width="170px">
          <el-form-item label="cookie:">
            <div style="width: 300px"><el-input v-model="cookie"></el-input></div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="uploadDataDialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="pullData">创建</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>

import "../../css/user/userManager.css";
import {request, timeTransform} from "../../store";

export default {
  name: 'UserManager',
  data() {
    return {

      timeRange: [new Date("1970-01-01 00:00"), new Date("2999-12-30 23:59")],

      keyWord: '',
      statusSelect: '',
      statusList: [],

      showCourseDetails: false,

      pageChange: false,
      pageNum: 1,
      pageSize: 10,
      totle: 0,
      tableData: [],
    }
  },

  mounted() {
    this.initFilterdata();
    this.onSearch();
  },

  methods: {
    // 初始化下拉框数据
    initFilterdata() {
      let response = request('get', 'user/filterdata', null);
      response.then(data => {
        for (let i = 0; i < data.data.length; i++) {
          let metadata = data.data[i];
          if (metadata.name === 'STATUS') {
            this.statusList = []
            this.initStatus(metadata);
          }
        }
      });
    },

    initStatus(metadata) {
      let metas = metadata.dataList
      let all = {};
      all.value = '';
      all.label = '全部';
      this.statusList.push(all);
      for(let i = 0; i < metas.length; i++) {
        let meta = {};
        meta.value = metas[i].name;
        meta.label = metas[i].name;
        this.statusList.push(meta);
      }
    },

    clear() {
      this.keyWord = ''
      this.statusSelect = ''
      this.timeRange = [new Date("1970-01-01 00:00"), new Date("2999-12-30 23:59")]
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.onSearch()
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.onSearch()
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },

    // 数据查询
    onSearch() {
      this.pageChange = true;
      let param = new Map();
      param.set("pageNum", this.pageNum);
      param.set("pageSize", this.pageSize);
      if (this.keyWord != null && this.keyWord != '') {
        param.set("keyWord", this.keyWord);
      }
      if (this.statusSelect != null && this.statusSelect != '') {
        param.set("status", this.statusSelect);
      }
      let response = request('get', 'user/list', param);
      response.then(data => {
        let recordList = data.data.data;
        this.totle = data.data.count;
        let temp = [];
        for (let i = 0; i < recordList.length; i++) {
          let record = {};
          record.id = recordList[i].id;
          record.wxid = recordList[i].wxid;
          record.nickname = recordList[i].nickname;
          record.headPortrait = recordList[i].headPortrait;
          record.realName = recordList[i].realName;
          record.phoneNum = recordList[i].phoneNum;
          record.type = recordList[i].type;
          record.status = recordList[i].status;
          record.order = recordList[i].order;
          record.createTime = timeTransform(new Date(recordList[i].createTime), "YYYY-MM-DD HH:ii:ss");
          temp.push(record);
        }
        this.tableData = temp;
      })
    },

    updateStatus(userId, status) {
      let param = {
        id: userId,
        status: status
      }
      let response = request('post', 'user/update/status', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("修改成功！")
          this.onSearch()
        } else {
          this.message("修改失败！")
        }
      })
    },

    goToLeaveMessage(id) {
      this.$router.push({
        path: '/leave-message-manager',
        query: {
          id: id
        }
      })
    },

    selectPaymentRecord(wxid) {
      this.$router.push({
        path: '/pay-manager',
        query: {
          openid: wxid
        }
      })
    }

  },

}
</script>