import axios from 'axios'

// import store from '../vuex/store'
import { Message } from 'element-ui'
import store from '../store'

// axios 配置
axios.defaults.timeout = 10000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true

// POST传参序列化
axios.interceptors.request.use((config) => {

    return config
}, (err) => {
    Message.error('参数错误')
    return Promise.reject(err)
})

// 返回状态判断
axios.interceptors.response.use((res) => {
    const response = res.data
    // 获取用户信息
    if (response.code == 200) {
        return response
    }
    if (response.code != 200) {
        Message.error(response.msg)
    }
    switch (response.code) {
        case 203:
            Message.error('未授权，请登录')
            window.location.href = store.getters.getManagerLoginHref
            break
        case 204:
            Message.error('未授权，请登录')
            window.location.href = store.getters.getWxLoginHref
            break
        case 404:
            Message.error('接口请求异常: ' + response.config.url + ', 请重试')
            break
        default:
    }
    return response
}, (err) => {
    if (err && err.response) {
        switch (err.response.code) {
            case 203:
                Message.error('未授权，请登录')
                window.location.href = store.getters.getManagerLoginHref
                break
            case 204:
                Message.error('未授权，请登录')
                window.location.href = store.getters.getWxLoginHref
                break
            case 404:
                Message.error('接口请求异常: ' + err.response.config.url + ', 请重试')
                break
            default:
                // Message.error('Oops, 出错啦')
        }
    }
})

export default function request (method, url, data) {
    // 初始化url
    url = store.getters.getDomain + url;

    if (method === 'get' && data != null && data != '') {
        let params = '';
        data.forEach(function(k, v) {
            params = params + '&' + v + '=' + k;
        })
        if (url.includes('?')) {
            params = '&' + params.substring(1, params.length);
        } else {
            params = '?' + params.substring(1, params.length);
        }
        url = url + params;
    }

    // 处理请求的url和数据
    data = method === 'get' ? { params: data } : data

    // 发送请求
    return new Promise((resolve, reject) => {
        axios[method](url, data)
            .then(response => {
                resolve(response)
            }, error => {
                reject(error)
            })
            .catch(error => {
                reject(error)
            })
        })
        .catch(error => {
            console.log(error)
        })
}
