<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="commodity-detail">

    <div class="commodity-detail-div">
      <table class="commodity-detail-table">
        <tr>
          <td colspan="2" class="commodity-detail-name">{{name}}</td>
        </tr>
        <tr>
          <td colspan="2" class="commodity-detail-price">￥{{displayPrice}}</td>
        </tr>
        <tr>
          <td colspan="2" class="commodity-detail-course">包含课程</td>
        </tr>
        <tr v-for="course in relatedCourseList" :key="course"  @click="goToCourse(course.id)">
          <td class="commodity-detail-name-td">{{course.name}}</td>
          <td class="commodity-course-price-td">￥{{course.displayPrice}}</td>
        </tr>
<!--        <tr>-->
<!--          <td class="commodity-detail-name-total-td">总价</td>-->
<!--          <td class="commodity-course-price-total-td">-->
<!--            <span id="original-prices">￥{{displayOriginalPrices}}</span>-->
<!--          </td>-->
<!--        </tr>-->
      </table>
    </div>
    <div class="commodity-course-button-div">
      <div style="color: #999999; padding-bottom: 30px">
        <if v-if="available">
          <span>已减去购买过的课程价格！</span>
        </if>
      </div>
      <div>
        <if v-if="available">
          <el-button type="primary" @click="goToBuyPage()">立即购买</el-button>
        </if>
      </div>
      <div>
        <if v-if="!available">
          <span style="color: #999999">已购买过此商品中包含的一个或多个课程！</span>
        </if>
      </div>
    </div>

    <div class="bottom-div">

    </div>
  </div>
</template>

<script>

import "../../../css/wx/commodity/commodityDetail.css";
 import {request, timeTransform} from "../../../store";
// import store from '../../store';

export default {
  name: 'CommodityList',
  components: {

  },
  data () {
    return {
      id: this.$route.query.id,
      name: '',
      displayPrice: '',
      displayOriginalPrices: '',
      available: '',
      desc: '',
      payee: '',
      createTime: '',
      relatedCourseList: [],
      paymentProtocolContent: ''
    }
  },

  mounted() {
    this.listPayRecord()
  },

  methods: {
    listPayRecord() {
      let response = request('get', 'wx/commodity/get?id=' + this.id);
      response.then(data => {
        if (data.code == 200) {
          this.name = data.data.name
          this.displayPrice = data.data.displayPrice
          this.displayOriginalPrices = data.data.displayOriginalPrices
          this.available = data.data.available
          this.desc = data.data.desc
          this.payee = data.data.payee
          this.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii:ss")
          this.relatedCourseList = data.data.relatedCourseList
          if (this.displayPrice != this.displayOriginalPrices) {
            let tmp = document.getElementById("original-prices");
            tmp.style = 'text-decoration: line-through;';
          }
        } else {
          this.message("查询失败！")
        }
      })
    },

    goToCourse(courseId) {
      this.$router.push({
        path: '/course',
        query: {
          id: courseId
        }
      })
    },

    goToBuyPage() {
      if (!this.available) {
        this.message("您曾经购买过此商品中包含的课程，本商品不可购买!")
        return
      }
      this.message("购买窗口暂时关闭!")
      // this.$router.push({
      //   path: '/buy-course',
      //   query: {
      //     commodityId: this.id,
      //     outTradeNo: this.generateOutTradeNo(),
      //   }
      // })
    },

    generateOutTradeNo() {
      let date = timeTransform(new Date(), "YYYYMMDD")
      return "4200001" + this.getRandom(3) + date + this.getRandom(10)
    },

    getRandom(len) {
      len = len || 32;
      let $chars = '0123456789';
      let maxPos = $chars.length;
      let pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>
<style >


</style>