<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

<!--  头部-->
  <div class="training-show">

<!--  训练内容-->
    <div class="training-show-content">
      <tr>
        <td colspan="2" class="training-show-title">{{this.title}}</td>
      </tr>
      <div class="training-show-user-info">
        <div>
          <img class="training-show-user-head-portrait" :src="headPortrait">
        </div>
        <div class="training-show-user-nickname">
          {{nickname}}
        </div>
      </div>

      <div class="monthly-training-notice-list">
        <div class="monthly-training-notice-content" v-for="notice in noticeList" :key="notice">
            &emsp;{{notice.content}}
        </div>
      </div>

      <div class="training-show-training-list" v-for="record in trainingRecordContentList" :key="record">
        <div class="training-show-content-title">
          {{record.title}}
        </div>
        <div class="training-show-content-textarea">
          &emsp;{{record.content}}
        </div>
      </div>
      <div class="training-show-create-time">
        &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{createTime}}
      </div>
    </div>

    <div class="bottom-div">
    </div>

  </div>
</template>

<script>

import "../../../css/wx/train/trainingShow.css";
import {request, timeTransform} from "../../../store";
// import store from '../../store';
// import {request, timeTransform} from "../../store";

export default {

  data () {
    return {
      title: this.$route.query.title,
      lessonId: this.$route.query.id,
      courseId: this.$route.query.courseId,
      noticeList: [],
      inputList: [],
      trainingRecordContentList: [],
      createTime: '',
      headPortrait: '',
      nickname: ''
    }
  },

  mounted() {
    this.initTrainingRecordInfo()
  },

  methods: {
    initTrainingRecordInfo() {
      let response = request('get', 'wx/training/get?lessonId=' + this.lessonId);
      response.then(data => {
        if (data.code == 200) {
          this.trainingRecordContentList = data.data.trainingRecordContentList
          this.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii")
          this.headPortrait = data.data.userHeadPortrait
          this.nickname = data.data.userNickname
        } else {
          this.message("查询失败！")
        }
      })

      let noticeList = []
      let teainingNoticeList = this.$route.query.noticeList
      for (let i = 0; i < teainingNoticeList.length; i++) {
        let notice = {
          content: teainingNoticeList[i].content
        }
        noticeList.push(notice)
      }
      this.noticeList = noticeList

    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>