<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="plan-create-header-div">
      <span><strong>新建飞行训练任务</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="plan-create-content-div">
        <div class="plan-create-base-info">
          课程创建
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课程名称:">
              <el-input style="width: 300px" v-model="name">
              </el-input>
            </el-form-item>
          </el-form>
<!--          <el-form :inline="true"  label-width="150px">-->
<!--            <el-form-item label="课程类型:">-->
<!--              <el-select style="width: 300px"-->
<!--                         @change="courseChange"-->
<!--                         v-model="typeSelect"-->
<!--                         placeholder="请选择">-->
<!--                <el-option-->
<!--                    v-for="item in typeList"-->
<!--                    :key="item.name"-->
<!--                    :label="item.name"-->
<!--                    :value="item.name">-->
<!--                </el-option>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-form>-->
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="封面图片:">
              <el-input style="width: 300px" v-model="img">
              </el-input>
            </el-form-item>
          </el-form>
          <UploadImage :limit="9" :imgList="fileImgList"
                       @handleRemove="handleRemove"
                       @onSuccessFiles="onSuccessImgFiles" />
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课程价格:">
              <el-input style="width: 300px" v-model="price">
              </el-input>
            </el-form-item>
            <el-form :inline="true"  label-width="150px">
              <el-form-item label="课程描述:">
                <el-input
                    type="textarea"
                    resize="none"
                    style="width: 300px"
                    :autosize="{ minRows: 6, maxRows: 20}"
                    v-model="contents"/>
              </el-form-item>
            </el-form>
          </el-form>
        </div>

        <div class="plan-create-pagination-div">
          <el-button type="primary" @click="commit()">创建</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/course/courseCreate.css";
import {request} from "../../store";
import UploadImage from '../../components/upload/UploadImage'

export default {
  data() {
    return {
      name: '',
      img: '',
      price: '',
      contents: '',
      typeSelect: '',
      typeList:[],
      fileImgList: []
    }
  },

  components: {
    UploadImage
  },

  mounted() {
    this.initSelectFilterdata();
  },

  methods: {

    // 第一个页面
    // 初始化下拉框数据
    initSelectFilterdata() {
      let response = request('get', 'course/filterdata', null);
      response.then(data => {
        let dataList = data.data
        this.typeList = this.initGroupType(dataList, 'TYPE', true)
      });
    },

    initGroupType(dataList, typeName, defaultEmpty) {
      for (let i = 0; i < dataList.length; i++) {
        let meta = dataList[i]
        if (meta.name != typeName) {
          continue
        }
        let all = {};
        all.id = '';
        all.name = '全部';
        let mdlist = [];
        if (!defaultEmpty) {
          mdlist.push(all)
        }
        meta.dataList.forEach(m => {
          let md = {};
          md.name = m.name;
          md.id = m.id;
          mdlist.push(md);
        })
        return mdlist;
      }
    },

    onSuccessImgFiles(files) {
      this.fileImgList = files
      this.img = files[0].url
      this.message("上传成功")
    },

    handleRemove() {
      this.fileImgList = []
      this.img = ''
    },

    cancel() {
      this.$router.go(-1)
    },

    commit() {
      if (this.name == '') {
        this.message("课程名称不能为空！");
        return;
      }

      if (this.img == '') {
        this.message("课程封面图片不能为空！");
        return;
      }

      if (this.price == '') {
        this.message("课程价格不能为空！");
        return;
      }

      // if (this.typeSelect == '') {
      //   this.message("课程类型不能为空！");
      //   return;
      // }

      if (this.contents == '') {
        this.message("课程描述不能为空！")
        return;
      }

      let param = {
        name: this.name,
        img: this.img,
        price: this.price,
        contents: this.contents,
        // type: this.typeSelect
      }
      let response = request('post', 'course/create', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("创建成功！")
          this.$router.push({  //核心语句
            path:'/course-manager',   //跳转的路径
          })
        } else {
          this.message("创建失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>