import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  //baseUrl: "http://172.30.229.132:8081/"
  // env: "dev",
  // env: "test",
  env: "prod",
  masterName: '涛哥交易心理工作室',
  masterHeadPortrait: 'https://mental-training.oss-cn-hangzhou.aliyuncs.com/img/taoge.jpeg',
  domainProd: "https://dlysedu.com/mt/",
  domainTest: "http://skiin.ink/mt/",
  domainDev: "http://localhost:8090/",
  managerLoginProdHref: "https://dlysedu.com/#/login",
  managerLoginTestHref: "http://skiin.ink/#/login",
  managerLoginDevHref: "http://localhost:8080/#/login",
  wxLoginProdHref: "https://dlysedu.com/mt/wx/login",
  wxLoginTestHref: "http://skiin.ink/mt/wx/login",
  wxLoginDevHref: "http://localhost:8080/#/wx/login",
  userInfo: '',
  uploadDisabled: false, // 管理后台上传按钮是否显示
  courseType: 'DEFAULT', // 管理后台上传文件所属课程的类型
}

const getters = {
  getManagerLoginHref() {
    if (state.env == "prod") {
      return state.managerLoginProdHref;
    }
    if (state.env == "test") {
      return state.managerLoginTestHref;
    }
    return state.managerLoginDevHref;
  },
  getWxLoginHref() {
    if (state.env == "prod") {
      return state.wxLoginProdHref;
    }
    if (state.env == "test") {
      return state.wxLoginTestHref;
    }
    return state.wxLoginDevHref;
  },
  getDomain() {
    if (state.env == "prod") {
      return state.domainProd;
    }
    if (state.env == "test") {
      return state.domainTest;
    }
    return state.domainDev;
  }
}

const store = new Vuex.Store({
  state,
  getters
})

export default store;

export { default as request } from '../tools/request'

export { default as timeTransform } from '../tools/timeTransform';

export { spliceList as spliceList } from '../tools/listSplice';

export { default as upload } from '../tools/aliyunUpload'

