<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

<!--  头部-->
  <div class="leave-message-page">

<!--    留言记录列表-->
    <mescroll-vue ref="mescroll" :down="mescrollUp" :up="mescrollDown" @init="mescrollInit">
      <div class="leave-message-div">
        <div class="contact-information-div">
          <div class="contact-information-phone">
<!--            客服电话：15504954659-->
          </div>
          <div class="contact-information-wechat">
<!--            客服微信：15504954659     pressure        -->
          </div>
        </div>

        <div class="comment-div" v-if="leaveMessageList.length != 0">

          <div class="comment-list-div" v-for="message in leaveMessageList" :key="message">
            <table class="comment-table" v-if="message.type == 'LEAVE_MESSAGE'">
              <tr>
                <td class="comment-user-head-portrait-td">
                  <img class="comment-user-head-portrait" :src="message.headPortrait"/>
                </td>
                <td class="comment-user-nickname-td">{{message.nickname}}</td>
                <td class="comment-user-create-time-td">{{message.createTime}}</td>
              </tr>
              <tr>
                <td colspan="3" class="comment-user-content-td">
                  <div class="leave-message-content">
                    {{message.content}}
                  </div>
                </td>
              </tr>
            </table>

            <table class="comment-table" v-if="message.type == 'REPLAY_MESSAGE'">
              <tr>
                <td class="comment-user-create-time-right-td">{{message.createTime}}</td>
                <td class="comment-user-nickname-right-td">{{message.nickname}}</td>
                <td class="comment-user-head-portrait-right-td">
                  <img class="comment-user-head-portrait" :src="message.headPortrait"/>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="comment-user-content-td">
                  <div class="replay-content">
                    {{message.content}}
                  </div>
                </td>
              </tr>
            </table>
          </div>

        </div>

      </div>


      <mt-tabbar fixed class="foot">
        <el-input placeholder="请输入信息"
                  @focus="add"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 20}"
                  v-model="content">
        </el-input>
        &nbsp;
        <el-button type="warning" @click="send">发送</el-button>
      </mt-tabbar>

      <div class="bottom-div">
      </div>
    </mescroll-vue>



  </div>
</template>

<script>

import "../../css/wx/leaveMessage.css";
import "../../css/wx/comment.css";
import store, {request, timeTransform} from "../../store";
import MescrollVue from 'mescroll.js/mescroll.vue'
import MintUI from 'mint-ui';
import 'mint-ui/lib/style.css'
import Vue from 'vue';
Vue.use(MintUI)

export default {

  components: {
    MescrollVue
  },

  data () {
    return {
      leaveMessageList: [],
      content: '',

      mescrollUp:{
        auto: true, // 是否在初始化完毕之后自动执行下拉回调callback; 默认true
        callback: this.refreshPage,
        // noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
      },
      mescrollDown: {
        auto: true, // 是否在初始化时以上拉加载的方式自动加载第一页数据; 默认false
        page: {
          num: 0,
          size: 50
        },
        callback: this.loadData,
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
      },
      mescroll: ''
    }
  },

  mounted() {
    // this.loadData(this.mescrollUp.page)
  },

  methods: {
    mescrollInit (mescroll) {
      this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },

    refreshPage() {
      this.mescroll.endSuccess()
    },

    loadData(page) {
      // 联网加载数据
      this.selectLeaveMessage(page.num, page.size, (curPageData) => {
        // 添加列表数据
        this.leaveMessageList = this.leaveMessageList.concat(curPageData)
        // 数据渲染成功后,隐藏下拉刷新的状态
        this.$nextTick(() => {
          this.mescroll.endSuccess(curPageData.length)
        })
      }, () => {
        // 联网失败的回调,隐藏下拉刷新和上拉加载的状态;
        this.mescroll.endErr()
      })
    },

    selectLeaveMessage(pageNum, pageSize, successCallback, errorCallback) {
      let param = new Map();
      param.set("pageNum", pageNum);
      param.set("pageSize", pageSize);
      let response = request('get', 'wx/leave/message/list', param);
      response.then(data => {
        if (data.code == 200) {
          let commentList = []
          let recordList = data.data.data;
          if (recordList == null || recordList.length == 0) {
            successCallback && successCallback(commentList)
            return;
          }
          recordList.forEach(message => {
            let temp = {
              id: message.id,
              nickname: message.nickname,
              headPortrait: message.headPortrait,
              content: message.content,
              type: message.type,
              createTime: timeTransform(new Date(message.createTime), "YYYY-MM-DD HH:ii")
            }
            commentList.push(temp)
          })
          successCallback && successCallback(commentList)
        } else {
          this.message("查询失败！")
          errorCallback && errorCallback()
        }
      })
    },

    add() {
      console.log(this.courseComment)
      this.$nextTick(function () {
        document.documentElement.scrollTop = 1000000;
      });
      setTimeout(() => {
        document.documentElement.scrollTop = 10000;
      }, 100);
    },

    send() {
      if (this.content == '') {
        this.message("评论内容不能为空！")
        return;
      }
      let param = {
        content: this.content
      }
      let response = request('post', 'wx/leave/message/create', param);
      response.then(data => {
        if (data.code == 200) {
          if (data.data != null && data.data != undefined && data.data) {
            this.message("评论成功！")
            let currentComment = {
              nickname: store.state.userInfo.nickname,
              headPortrait: store.state.userInfo.headPortrait,
              content: this.content,
              type: 'LEAVE_MESSAGE',
              createTime: timeTransform(new Date(), "YYYY-MM-DD HH:ii")
            }
            let list = []
            this.leaveMessageList.forEach(comment => {
              list.push(comment)
            })
            list.push(currentComment)
            this.leaveMessageList = list
            this.content = ''
          }
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>