<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\CourseManager.vue
-->
<template>
  <div id='data-manager' style="padding: 10px 10px 10px; background-color: #F1F4F8">
    <div id="data-header" class="data-header-div">
      <span><strong>用户管理</strong></span>
    </div>
    <div id="data-serch" class="data-serch-div">
      <div id="data-serch-title" class="data-serch-title-div">筛选</div>
      <el-form :inline="true"   class="demo-form-inline1">
        <el-form-item label="课程:">
          <el-select v-model="courseId" placeholder="请选择">
            <el-option
              v-for="item in courseList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型:">
          <el-select v-model="type" placeholder="请选择">
            <el-option
                v-for="item in typeList"
                :key="item.label"
                :label="item.label"
                :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名:">
          <el-input style="width: 240px" v-model="keyWord" placeholder="精确查询  用户昵称/wxopid/手机号">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="clear">清空选项</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div id="data-content" class="data-content-div">
      <div id="data-option" class="data-option-div">
        <div style="text-align: left; width: 50%; color: #333333; font-size: 18px; float:left">用户列表</div>
      </div>

      <div id="data-table" class="data-table-div">
        <el-table
            :data="tableData"
            border
            ref="select"
            :header-cell-style="{background:'#F0F4F8', 'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="id"
              width="80">
          </el-table-column>
          <el-table-column
              prop="nickname"
              label="微信昵称">
          </el-table-column>
          <el-table-column
              prop=""
              #default="scope"
              label="头像">
            <el-image :src="scope.row.headPortrait" class="cource-img">
            </el-image>
          </el-table-column>
          <el-table-column
              prop="courseName"
              label="课程名称">
          </el-table-column>
          <el-table-column
              prop="lessonName"
              label="课时名称">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态"
              width="100">
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="创建时间"
              width="200">
          </el-table-column>
          <el-table-column
              label="操作"
              width="150">
            <template slot-scope="scope">
              <el-button type="primary" @click="openDetail(scope.row)">
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
<!--      分页-->
      <div class="el-pagination-div">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totle">
        </el-pagination>
      </div>
    </div>

    <!--    数据上传弹窗-->
    <el-dialog  style="color: #EDB244; font-size: 40px" :visible.sync="showCourseDetails" width="710px">
      <div class="training-show-content">
        <tr>
          <td colspan="2" class="training-show-title">{{courseDetail.lessonName}}</td>
        </tr>
        <div class="training-show-user-info">
          <div>
            <img class="training-show-user-head-portrait" :src="courseDetail.headPortrait">
          </div>
          <div class="training-show-user-nickname">
            {{courseDetail.nickname}}
          </div>
        </div>

        <div class="training-show-training-list" v-for="record in courseDetail.trainingRecordContentList" :key="record">
          <div class="training-show-content-title">
            {{record.title}}
          </div>
          <div class="training-show-content-textarea">
            &emsp;{{record.content}}
          </div>
        </div>
        <div class="training-show-create-time">
          &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;{{courseDetail.createTime}}
        </div>
      </div>
      <div slot="footer">
        <el-button @click="showCourseDetails = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>

import "../../css/train/trainManager.css";
import {request, timeTransform} from "../../store";

export default {
  data() {
    return {

      timeRange: [new Date("1970-01-01 00:00"), new Date("2999-12-30 23:59")],

      keyWord: '',
      courseId: '',
      courseList: [],
      type: '',
      typeList: [],

      showCourseDetails: false,
      courseDetail: '',

      pageChange: false,
      pageNum: 1,
      pageSize: 10,
      totle: 0,
      tableData: [],
    }
  },

  mounted() {
    this.initFilterdata();
    this.onSearch();
  },

  methods: {
    // 初始化下拉框数据
    initFilterdata() {
      let response = request('get', 'lesson/filterdata', null);
      response.then(data => {
        this.courseList = this.initGroupType(data.data, 'COURSE_DATA', false)
        this.typeList = this.initGroupType(data.data, 'TRAINING_RECORD_TYPE', false)
      });
    },

    initGroupType(dataList, typeName, defaultEmpty) {
      for (let i = 0; i < dataList.length; i++) {
        let meta = dataList[i]
        if (meta.name != typeName) {
          continue
        }
        let all = {};
        all.label = '全部';
        all.id = '';
        all.value = '';
        let mdlist = [];
        if (!defaultEmpty) {
          mdlist.push(all)
        }
        meta.dataList.forEach(m => {
          let md = {};
          md.id = m.id;
          md.value = m.id;
          md.label = m.name;
          mdlist.push(md);
        })
        return mdlist;
      }
    },

    clear() {
      this.keyWord = ''
      this.statusSelect = ''
      this.timeRange = [new Date("1970-01-01 00:00"), new Date("2999-12-30 23:59")]
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.onSearch()
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.onSearch()
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },

    // 数据查询
    onSearch() {
      this.pageChange = true;

      let param = new Map();
      if (this.courseId != '') {
        param.set("courseId", this.courseId);
      }
      if (this.type != '') {
        param.set("type", this.type);
      }
      if (this.keyWord != '') {
        param.set("keyWord", this.keyWord);
      }
      param.set("pageNum", this.pageNum);
      param.set("pageSize", this.pageSize);

      let response = request('get', 'training/list', param);
      response.then(data => {
        let recordList = data.data.data;
        this.totle = data.data.count;
        let temp = [];
        for (let i = 0; i < recordList.length; i++) {
          let record = {};
          record.id = recordList[i].id;
          record.userId = recordList[i].userId;
          record.nickname = recordList[i].userNickname;
          record.headPortrait = recordList[i].userHeadPortrait;
          record.courseName = recordList[i].courseName;
          record.lessonName = recordList[i].lessonName;
          record.type = recordList[i].type;
          record.status = recordList[i].status;
          record.trainingRecordContentList = recordList[i].trainingRecordContentList;
          record.createTime = timeTransform(new Date(recordList[i].createTime), "YYYY-MM-DD HH:ii:ss");
          temp.push(record);
        }
        this.tableData = temp;
      })
    },

    updateStatus(userId, status) {
      let param = {
        id: userId,
        status: status
      }
      let response = request('post', 'user/update/status', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("修改成功！")
          this.onSearch()
        } else {
          this.message("修改失败！")
        }
      })
    },

    goToLeaveMessage(id) {
      this.$router.push({
        path: '/leave-message-manager',
        query: {
          id: id
        }
      })
    },

    openDetail(row) {
      console.log(row)
      this.courseDetail = row
      this.showCourseDetails = true
    }
  },

}
</script>