/*
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 11:12:12
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Meta from "vue-meta";
// import Navigation from 'vue-navigation';

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(Meta);

new Vue({
  router,
  store,
  // enablePullDownRefresh: true,
  render: h => h(App)
}).$mount('#app')

// Vue.use(Navigation, { router });


