<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

  <div class="register">
    <div class="register-div">
      <table class="register-table">
        <tr>
          <td colspan="2" class="register-headPortrait-td">
            <img class="register-headPortrait" :src="headPortrait">
          </td>
        </tr>
        <tr>
          <td colspan="2" class="register-nickname">hello,{{nickname}}!</td>
        </tr>
        <tr>
          <td colspan="2" class="register-phone-num">
            <el-input v-model="realName" placeholder="真实姓名" />
          </td>
        </tr>
        <tr>
          <td colspan="2" class="register-phone-num">
            <el-input v-model="phoneNum" placeholder="手机号" />
          </td>
        </tr>
        <tr>
          <td class="register-captcha">
            <el-input v-model="captcha" placeholder="验证码" />
          </td>
          <td class="captcha-send-td">
            <el-button type="primary" @click="sendCaptcha()" id="send-button">发送验证码</el-button>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="register-protocol-td">
            <span @click="dialogShow=true">注册协议</span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="register-button">
            <el-button type="primary" @click="regist()">注册</el-button>
          </td>
        </tr>
      </table>
    </div>

    <el-dialog title="商品购买协议" style="color: #EDB244; font-size: 16px;" :visible.sync="dialogShow" width="340px">
      <div>
        <table class="register-protocol-signed-table">
          <tr>
            <td colspan="2">
              <div class="register-protocol-list" v-for="content in paymentProtocolContentList" :key="content">
                <p class="register-protocol-format">
                  &emsp;&emsp;{{content.content}}
                </p>
              </div>
            </td>
          </tr>
          <tr class="register-protocol-signed-tr">
            <td class="register-protocol-signed-td">
              <el-radio-group>
                <el-checkbox @change="signPaymentProtocol"/>
              </el-radio-group>
            </td>
            <td class="register-protocol-confirm-td">我已阅读协议并自愿签署！</td>
          </tr>
          <tr class="register-protocol-signed-tr">
            <td colspan="2">
              <el-button type="primary" @click="dialogShow=false">关闭</el-button>
            </td>
          </tr>
        </table>
      </div>
    </el-dialog>

    <div class="bottom-div">

    </div>
  </div>
</template>

<script>

import "../../../css/wx/register/registerPage.css";
import store, { request} from "../../../store";

export default {
  name: 'RegisterPage',
  components: {
  },
  data () {
    return {
      nickname: '',
      headPortrait: '',
      realName: '',
      phoneNum: '',
      captcha: '',
      sendTime: '',
      protocolId: '',
      paymentProtocolContentList: [],
      signed: false,
      dialogShow: false,
      timer: ''
    }
  },

  mounted() {
    this.initUserInfo();
    this.selectPaymentProtocol();
  },

  destroyed() {
    clearInterval(this.timer);
  },

  methods: {
    initUserInfo() {
      this.nickname = store.state.userInfo.nickname
      this.headPortrait = store.state.userInfo.headPortrait
    },

    sendCaptcha() {
      if(!(/^1[3456789]\d{9}$/.test(this.phoneNum))){
        this.message("手机号码有误，请重填!");
        return
      }
      let interval = 60 - (Math.round(new Date().getTime() / 1000) - Math.round(this.sendTime / 1000))
      if (interval > 0) {
        this.message("请在" + interval + "秒后重新发送！")
        return
      }
      let response = request('post', 'wx/user/send/captcha?phoneNum=' + this.phoneNum, null);
      response.then(data => {
        if (data.code == 200 && data.data) {
          this.sendTime = new Date().getTime();
          let sendButton = document.getElementById('send-button');
          sendButton.innerHTML = "已发送"
          sendButton.disabled = 'disabled'
          this.timer = setTimeout(() => {
            let sendButton = document.getElementById('send-button');
            sendButton.innerHTML = "发送验证码"
            sendButton.disabled = ''
          }, 60 * 1000)
          this.message("验证码已发送！")
        } else {
          this.message("发送失败！")
        }
      })
    },

    selectPaymentProtocol() {
      let response = request('get', 'wx/protocol/get');
      response.then(data => {
        if (data.code == 200
            && data.data.contents != null
            && data.data.contents != ''
            && data.data.contents != undefined) {
          this.protocolId = data.data.id
          this.paymentProtocolContentList = data.data.contents
        }
      })
    },

    signPaymentProtocol() {
      if (this.signed) {
        this.signed = false
      } else {
        this.signed = true
      }
    },

    regist() {
      if (this.realName == '') {
        this.message("请填写真实姓名!")
        return;
      }
      if (this.phoneNum == '') {
        this.message("请填写手机号!")
        return;
      }
      if (this.captcha == '') {
        this.message("请填写验证码!")
        return;
      }
      if(!(/^1[3456789]\d{9}$/.test(this.phoneNum))){
        this.message("手机号码有误，请重填!");
        return
      }
      if (!this.signed) {
        this.message("请先签署商品购买协议！")
        return
      }
      let response = request('post', 'wx/user/regist?realName=' + this.realName + '&phoneNum=' + this.phoneNum + "&protocolId=" + this.protocolId + "&captcha=" + this.captcha, null);
      response.then(data => {
        if (data.code == 200 && data.data) {
          this.message("注册成功！")
          this.$router.push({
            path: '/wxIndex'
          })
        } else {
          this.message("注册失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }
  }

}
</script>
<style >
.time-instruction{
  white-space: pre-line;
}
</style>