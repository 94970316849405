<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="plan-create-header-div">
      <span><strong>修改课时信息</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="plan-create-content-div">
        <div class="plan-create-base-info">
          课时信息修改
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时名称:">
              <el-input style="width: 300px" v-model="name">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时序号:">
              <el-input style="width: 300px" v-model="index">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课件地址:">
              <el-input style="width: 300px" v-model="coursewareUrl">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时音视频地址:">
              <el-input style="width: 300px" v-model="videoUrl">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="解读音视频地址:">
              <el-input style="width: 300px" v-model="interpretVideoUrl">
              </el-input>
            </el-form-item>
          </el-form>
          <UploadVideo :limit="9" :fileList="fileList"
                       @handleRemove="handleRemove"
                       @onSuccessFiles="onSuccessImgFiles" />
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="解读文字描述:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="content"/>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时训练提示:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="trainingNotice"/>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课时训练模板:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="trainingConfig"/>
            </el-form-item>
          </el-form>
        </div>

        <div class="plan-create-pagination-div">
          <el-button type="primary" @click="update()">修改</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/lesson/lessonUpdate.css";
import store, {request} from "../../store";
import UploadVideo from '../../components/upload/UploadVideo'

export default {
  data() {
    return {
      id: this.$route.query.id,
      name: '',
      index: '',
      coursewareUrl: '',
      videoUrl: '',
      interpretVideoUrl: '',
      content: '',
      trainingNotice: '',
      trainingConfig: '',
      fileList: [],
    }
  },

  components: {
    UploadVideo
  },

  mounted() {
    this.initDate()
    //this.initSelectFilterdata();
  },

  methods: {

    initDate() {
      let response = request('get', 'lesson/get?id=' + this.id, null);
      response.then(data => {
        if (data.code == 200) {
          store.state.courseType = data.data.courseType
          this.name = data.data.name
          this.index = data.data.index
          this.coursewareUrl = data.data.coursewareUrl
          this.videoUrl = data.data.videoUrl,
          this.interpretVideoUrl = data.data.interpretVideoUrl,
          this.content = data.data.content,
          this.trainingNotice = data.data.trainingNotice
          this.trainingConfig = data.data.trainingConfig
          if (data.data.videoUrl != '' && data.data.videoUrl != undefined) {
            this.fileList = [{name: 'videoUrl', url: data.data.videoUrl}]
          }
          if (data.data.interpretVideoUrl != '' && data.data.interpretVideoUrl != undefined) {
            this.fileList.push({name: 'interpretVideoUrl', url: data.data.interpretVideoUrl})
            store.state.uploadDisabled = true;
          }
        } else {
          this.message("查询失败！")
        }
      });
    },

    // 第一个页面
    // 初始化下拉框数据
    initSelectFilterdata() {
      let response = request('get', 'sim/task/filterdata', null);
      response.then(data => {
        let dataList = data.data
        this.taskDeliveryTypeList = this.initGroupType(dataList, 'TASK_DELIVERY_TYPE', true)
        this.caseList = this.initGroupType(dataList, 'SIM_CASE', true)
      });
    },

    initGroupType(dataList, typeName, defaultEmpty) {
      for (let i = 0; i < dataList.length; i++) {
        let meta = dataList[i]
        if (meta.name != typeName) {
          continue
        }
        let all = {};
        all.id = '';
        all.name = '全部';
        let mdlist = [];
        if (!defaultEmpty) {
          mdlist.push(all)
        }
        meta.dataList.forEach(m => {
          let md = {};
          md.name = m.name;
          md.id = m.id;
          md.expand = m.expand;
          mdlist.push(md);
        })
        return mdlist;
      }
    },

    onSuccessImgFiles(files) {
      this.fileList = files
      this.videoUrl = files[0].url
      if (files.length > 1) {
        this.interpretVideoUrl = files[1].url
      }
      this.message("上传成功")
    },

    handleRemove(files) {
      if (files.length > 0) {
        this.videoUrl = files[0].url
        this.interpretVideoUrl = ''
      } else {
        this.videoUrl = ''
        this.interpretVideoUrl = ''
      }
      this.fileList = files
    },

    cancel() {
      this.$router.go(-1)
    },

    update() {
      if (this.name == '') {
        this.message("课程名称不能为空！");
        return;
      }

      if (this.img == '') {
        this.message("课程封面图片不能为空！");
        return;
      }

      if (this.price == '') {
        this.message("课程价格不能为空！");
        return;
      }

      if (this.videoUrl == '') {
        this.message("课程音频不能为空！");
        return;
      }

      if (this.desc == '') {
        this.message("课程描述不能为空！")
        return;
      }

      let param = {
        id: this.id,
        name: this.name,
        index: this.index,
        coursewareUrl: this.coursewareUrl,
        videoUrl: this.videoUrl,
        interpretVideoUrl: this.interpretVideoUrl,
        contents: this.content,
        trainingNotice: this.trainingNotice,
        trainingConfig: this.trainingConfig
      }
      let response = request('post', 'lesson/update', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("修改成功！")
          this.$router.push({  //核心语句
            path:'/lesson-manager',   //跳转的路径
          })
        } else {
          this.message("修改失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>