<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="notice-create-header-div">
      <span><strong>公告资讯</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="notice-create-content-div">
        <div class="notice-create-base-info">
          公告创建
        </div>
        <div class="notice-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="协议名称:">
              <el-input style="width: 300px" v-model="name">
              </el-input>
            </el-form-item>
          </el-form>

          <el-form :inline="true"  label-width="150px">
            <el-form-item label="协议内容:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="content"/>
            </el-form-item>

          </el-form>
        </div>

        <div class="notice-create-pagination-div">
          <el-button type="primary" @click="commit()">创建</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/paymentProtocol/paymentProtocolCreate.css";
import store, {request} from "../../store";

export default {
  data() {
    return {
      name: '',
      content: '',
    }
  },

  components: {
  },

  mounted() {
    store.state.courseType = 'DEFAULT'
  },

  methods: {

    handleRemove(files) {
      if (files.length > 0) {
        this.img = files[0].url
      }
      this.fileList = files
    },

    cancel() {
      this.$router.go(-1)
    },

    commit() {
      if (this.name == '') {
        this.message("协议名称不能为空！");
        return;
      }

      if (this.content == '') {
        this.message("协议内容不能为空！");
        return;
      }

      let param = {
        name: this.name,
        content: this.content,
      }
      let response = request('post', 'protocol/create', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("创建成功！")
          this.$router.push({  //核心语句
            path:'/payment-protocol-manager',   //跳转的路径
          })
        } else {
          this.message("创建失败！")
        }
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>