<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\CourseManager.vue
-->
<template>
  <div id='data-manager' style="padding: 10px 10px 10px; background-color: #F1F4F8">
    <div id="data-header" class="data-header-div">
      <span><strong>支付管理</strong></span>
    </div>
    <div id="data-serch" class="data-serch-div">
      <div id="data-serch-title" class="data-serch-title-div">筛选</div>
      <el-form :inline="true"   class="demo-form-inline1">
        <el-form-item label="状态:">
          <el-select v-model="statusSelect" placeholder="请选择">
            <el-option
                v-for="item in statusList"
                :key="item.label"
                :label="item.label"
                :value="item.label">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品:">
          <el-select v-model="commodityId" placeholder="请选择">
            <el-option
                v-for="item in commodityList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="搜索关键字:">
          <el-input style="width: 240px" v-model="keyWord" placeholder="openid/定单号">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="clear">清空选项</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div id="data-content" class="data-content-div">
      <div id="data-option" class="data-option-div">
        <div style="text-align: left; width: 50%; color: #333333; font-size: 18px; float:left">支付列表</div>
      </div>

      <div id="data-table" class="data-table-div">
        <el-table
            :data="tableData"
            border
            ref="select"
            :header-cell-style="{background:'#F0F4F8', 'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="id"
              width="80">
          </el-table-column>
          <el-table-column
              prop="openid"
              label="微信openid">
          </el-table-column>
          <el-table-column
              prop="nickname"
              label="微信昵称">
          </el-table-column>
          <el-table-column
              prop="commodity"
              label="购买课程名称">
          </el-table-column>
          <el-table-column
              prop="displayPrice"
              label="支付金额"
              width="120">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态"
              width="180">
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="开始时间"
              width="200">
          </el-table-column>
          <el-table-column
              label="操作"
              width="240">
              <template slot-scope="scope">
                <el-button type="primary" @click="selectDetails(scope.row.id)">
                  详细信息
                </el-button>
              </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页-->
      <div class="el-pagination-div">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totle">
        </el-pagination>
      </div>
    </div>

    <el-dialog title="订单信息" style="color: #EDB244;" :visible.sync="showDetails" width="800px">
      <table >
        <tr>
          <td class="payment-record-title-td">订单号：</td>
          <td class="payment-record-content-td">{{paymentRecord.outTradeNo}}</td>
          <td class="payment-record-title-td">微信流水号：</td>
          <td class="payment-record-content-td">{{paymentRecord.transactionId}}</td>
        </tr>
        <tr>
          <td class="payment-record-title-td">付款人：</td>
          <td class="payment-record-content-td">{{paymentRecord.nickname}}</td>
          <td class="payment-record-title-td">openid：</td>
          <td class="payment-record-content-td">{{paymentRecord.openid}}</td>
        </tr>
        <tr>
          <td class="payment-record-title-td">课程名称：</td>
          <td class="payment-record-content-td">{{paymentRecord.commodity}}</td>
          <td class="payment-record-title-td">交易金额：</td>
          <td class="payment-record-content-td">{{paymentRecord.displayPrice}}</td>
        </tr>
        <tr>
          <td class="payment-record-title-td">收款人：</td>
          <td class="payment-record-content-td">{{paymentRecord.merchantName}}</td>
          <td class="payment-record-title-td">交易状态：</td>
          <td class="payment-record-content-td">{{paymentRecord.status}}</td>
        </tr>
        <tr>
          <td class="payment-record-title-td">付款时间：</td>
          <td class="payment-record-content-td">{{paymentRecord.createTime}}</td>
          <td class="payment-record-title-td">完成时间：</td>
          <td class="payment-record-content-td">{{paymentRecord.updateTime}}</td>
        </tr>
        <tr>
          <td class="payment-record-title-td">失败原因：</td>
          <td class="payment-record-content-td">{{paymentRecord.payErrorReason}}</td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </el-dialog>

  </div>
</template>
<script>

import "../../css/pay/payManager.css";
import {request, timeTransform} from "../../store";

export default {
  name: 'PayManager',
  data() {
    return {
      timeRange: [new Date("1970-01-01 00:00"), new Date("2999-12-30 23:59")],

      keyWord: '',
      statusSelect: '',
      statusList: [],

      commodityId: '',
      commodityList: [],

      showCourseDetails: false,

      pageChange: false,
      pageNum: 1,
      pageSize: 10,
      totle: 0,
      tableData: [],

      paymentRecord: {},
      showDetails: false
    }
  },

  mounted() {
    this.initFilterdata();
    this.keyWord = this.$route.query.openid
    this.onSearch();
  },

  methods: {
    // 初始化下拉框数据
    initFilterdata() {
      let response = request('get', 'payment/filterdata', null);
      response.then(data => {
        for (let i = 0; i < data.data.length; i++) {
          let metadata = data.data[i];
          if (metadata.name === 'STATUS') {
            this.statusList = this.initMeta(metadata);
          }
          if (metadata.name === 'COURSE_DATA') {
            this.commodityList = this.initMeta(metadata);
          }
        }
      });
    },

    initMeta(metadata) {
      let metas = metadata.dataList
      let all = {};
      let metaList = []
      all.value = '';
      all.label = '全部';
      metaList.push(all);
      for(let i = 0; i < metas.length; i++) {
        let meta = {};
        meta.value = metas[i].id;
        meta.label = metas[i].name;
        metaList.push(meta)
      }
      return metaList
    },

    clear() {
      this.keyWord = ''
      this.statusSelect = ''
      this.timeRange = [new Date("1970-01-01 00:00"), new Date("2999-12-30 23:59")]
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      console.log(val)
      this.onSearch()
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.onSearch()
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },

    // 数据查询
    onSearch() {
      this.pageChange = true;
      let param = new Map();
      param.set("pageNum", this.pageNum);
      param.set("pageSize", this.pageSize);
      if (this.keyWord != null && this.keyWord != '') {
        param.set("keyWord", this.keyWord);
      }
      if (this.statusSelect != null && this.statusSelect != '') {
        param.set("status", this.statusSelect);
      }
      if (this.commodityId != null && this.commodityId != '') {
        param.set("commodityId", this.commodityId)
      }
      let response = request('get', 'payment/list', param);
      response.then(data => {
        let recordList = data.data.data;
        this.totle = data.data.count;
        let temp = [];
        for (let i = 0; i < recordList.length; i++) {
          let record = {};
          record.id = recordList[i].id;
          record.userId = recordList[i].userId;
          record.openid = recordList[i].openid;
          record.nickname = recordList[i].nickname;
          record.outTradeNo = recordList[i].outTradeNo;
          record.commodity = recordList[i].commodity;
          record.merchantName = recordList[i].merchantName;
          record.displayPrice = recordList[i].displayPrice;
          record.currency = recordList[i].currency;
          record.paymentMethod = recordList[i].paymentMethod;
          record.status = recordList[i].status;
          record.createTime = timeTransform(new Date(recordList[i].createTime), "YYYY-MM-DD HH:ii:ss");
          if (recordList[i].paySuccessTime != null) {
            record.paySuccessTime = timeTransform(new Date(recordList[i].paySuccessTime), "YYYY-MM-DD HH:ii:ss");
          }
          if (recordList[i].payErrorTime != null) {
            record.payErrorTime = timeTransform(new Date(recordList[i].payErrorTime), "YYYY-MM-DD HH:ii:ss");
          }
          record.payErrorReason = recordList[i].payErrorReason;
          temp.push(record);
        }
        this.tableData = temp;
      })
    },

    selectDetails(id) {
      this.paymentRecord = {}
      let response = request('get', 'payment/get?id=' + id, null);
      response.then(data => {
        let record = {}
        record.id = data.data.id;
        record.userId = data.data.userId;
        record.openid = data.data.openid;
        record.nickname = data.data.nickname;
        record.outTradeNo = data.data.outTradeNo;
        record.transactionId = data.data.transactionId;
        record.commodity = data.data.commodity;
        record.merchantName = data.data.merchantName;
        record.displayPrice = data.data.displayPrice;
        record.currency = data.data.currency;
        record.paymentMethod = data.data.paymentMethod;
        record.status = data.data.status;
        record.createTime = timeTransform(new Date(data.data.createTime), "YYYY-MM-DD HH:ii:ss");
        record.updateTime = timeTransform(new Date(data.data.updateTime), "YYYY-MM-DD HH:ii:ss");
        if (data.data.paySuccessTime != null) {
          record.paySuccessTime = timeTransform(new Date(data.data.paySuccessTime), "YYYY-MM-DD HH:ii:ss");
        }
        if (data.data.payErrorTime != null) {
          record.payErrorTime = timeTransform(new Date(data.data.payErrorTime), "YYYY-MM-DD HH:ii:ss");
        }
        record.payErrorReason = data.data.payErrorReason;
        this.paymentRecord = record
        this.showDetails = true
      })
    }

  },

}
</script>