<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-sim-bvlos-task\src\views\TaskCreate.vue
-->
<template>
  <div id='train-create' style="padding: 0px; background-color: #F1F4F8">
    <div id="train-header" class="plan-create-header-div">
      <span><strong>修改课程信息</strong></span>
    </div>
    <div style="min-height: 800px; padding-top: 20px; background-color: #ffffff;">
      <div id="task-first-content" class="plan-create-content-div">
        <div class="plan-create-base-info">
          课程创建
        </div>
        <div class="plan-create-base-content">
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课程名称:">
              <el-input style="width: 300px" v-model="name">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="默认所属商品:">
              <el-select style="width: 300px"
                         @change="commodityChange"
                         v-model="commoditySelect"
                         placeholder="请选择">
                <el-option
                    v-for="item in commodityList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="封面图片:">
              <el-input style="width: 300px" v-model="img">
              </el-input>
            </el-form-item>
          </el-form>
          <UploadImage :limit="9" :imgList="fileImgList"
                       @handleRemove="handleRemove"
                       @onSuccessFiles="onSuccessImgFiles" />
        </div>
        <div>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课程价格:">
              <el-input style="width: 300px" v-model="price">
              </el-input>
            </el-form-item>
          </el-form>
          <el-form :inline="true"  label-width="150px">
            <el-form-item label="课程描述:">
              <el-input
                  type="textarea"
                  resize="none"
                  style="width: 300px"
                  :autosize="{ minRows: 6, maxRows: 20}"
                  v-model="contents"/>
            </el-form-item>
          </el-form>
        </div>


        <div class="plan-create-pagination-div">
          <el-button type="primary" @click="update()">修改</el-button>
          <el-button type="primary" @click="cancel()">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import "../../css/course/courseUpdate.css";
import store, {request} from "../../store";
import UploadImage from '../../components/upload/UploadImage'

export default {
  data() {
    return {
      id: this.$route.query.id,
      name: '',
      img: '',
      price: '',
      contents: '',
      fileImgList: [],
      commodityList: [],
      commoditySelect: ''
    }
  },

  components: {
    UploadImage
  },

  mounted() {
    this.initDate()
    this.initSelectFilterdata();
  },

  methods: {

    initDate() {
      let response = request('get', 'course/get?id=' + this.id, null);
      response.then(data => {
        if (data.code == 200) {
          store.state.courseType = data.data.courseType
          this.name = data.data.name
          this.img = data.data.img
          this.price = data.data.price
          this.contents = data.data.contents
          this.commoditySelect = data.data.defaultCommodity
          if (data.data.img != '' && data.data.img != undefined) {
            this.fileImgList = [{name: 'image', url: data.data.img}]
            store.state.uploadDisabled = true;
          }
        } else {
          this.message("查询失败！")
        }
      });
    },

    // 第一个页面
    // 初始化下拉框数据
    initSelectFilterdata() {
      let response = request('get', 'course/filterdata', null);
      response.then(data => {
        let dataList = data.data
        this.commodityList = this.initGroupType(dataList, 'COMMODITY_LIST', true)
      });
    },

    initGroupType(dataList, typeName, defaultEmpty) {
      for (let i = 0; i < dataList.length; i++) {
        let meta = dataList[i]
        if (meta.name != typeName) {
          continue
        }
        let all = {};
        all.id = '';
        all.name = '全部';
        let mdlist = [];
        if (!defaultEmpty) {
          mdlist.push(all)
        }
        meta.dataList.forEach(m => {
          let md = {};
          md.name = m.name;
          md.id = m.id;
          md.expand = m.expand;
          mdlist.push(md);
        })
        return mdlist;
      }
    },

    cancel() {
      this.$router.back()
      // history.back()
    },

    commodityChange() {

    },

    update() {
      if (this.name == '') {
        this.message("课程名称不能为空！");
        return;
      }

      if (this.img == '') {
        this.message("课程封面图片不能为空！");
        return;
      }

      if (this.price == '') {
        this.message("课程价格不能为空！");
        return;
      }

      if (this.desc == '') {
        this.message("课程描述不能为空！")
        return;
      }

      let param = {
        id: this.id,
        name: this.name,
        img: this.img,
        price: this.price,
        defaultCommodity: this.commoditySelect,
        contents: this.contents,
      }
      let response = request('post', 'course/update', param);
      response.then(data => {
        if (data.code == 200) {
          this.message("修改成功！")
          this.$router.push({  //核心语句
            path:'/course-manager',   //跳转的路径
          })
        } else {
          this.message("修改失败！")
        }
      })
    },

    onSuccessImgFiles(files) {
      this.fileImgList = files
      this.img = files[0].url
      this.message("上传成功")
    },

    handleRemove() {
      this.fileImgList = []
      this.img = ''
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },
  }
}
</script>