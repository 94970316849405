<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

<!--  头部-->
  <div class="personal">

<!--    个人信息-->
  <div class="personal-info-div">
    <div class="personal-info-head-portrait-div">
      <img class="personal-info-head-portrait" :src="user.headPortrait">
    </div>
    <div class="personal-info-content">
      <tr>
        <td class="personal-info-nickname-td">{{this.user.nickname}}</td>
      </tr>
      <tr>
        <td class="personal-info-create-td">{{this.user.createTime}}</td>
      </tr>
    </div>
  </div>

<!--    功能区-->
<!--    <div class="personal-function-div">-->
<!--      <div class="personal-function-button">-->
<!--        <el-button class="personal-function-button" type="primary" @click="goToTrainingRecord()">训练记录</el-button>-->
<!--      </div>-->
<!--      <div class="personal-function-button">-->
<!--        <el-button class="personal-function-button" type="primary" @click="goToPaymentRecord()">付费记录</el-button>-->
<!--      </div>-->
<!--    </div>-->


    <div class="bottom-div">
    </div>

    <tab-bar>
      <tab-bar-item path="/wxIndex">
        <img slot="item-icon" src="../../assets/img/tabbar/icon_home.png" alt="首页">
        <img slot="item-icon-active" src="../../assets/img/tabbar/icon_home_active.png" alt="首页">
        <div slot="item-text">首页</div>
      </tab-bar-item>
      <tab-bar-item path="/wxPersonal">
        <img slot="item-icon" src="../../assets/img/tabbar/icon_home.png" alt="我的">
        <img slot="item-icon-active" src="../../assets/img/tabbar/icon_home_active.png" alt="我的">
        <div slot="item-text">我的</div>
      </tab-bar-item>
    </tab-bar>

  </div>
</template>

<script>

import "../../css/wx/personalPage.css";
// import store from '../../store';
import TabBar from '../../components/tabbar/TabBar.vue'
import TabBarItem from '../../components/tabbar/TabBarItem.vue'
import {request, timeTransform} from "../../store";

export default {
  components: {
    TabBar,
    TabBarItem
  },
  data () {
    return {
      user: '',
    }
  },

  mounted() {
    this.initPersonalInfo();
  },

  methods: {

    initPersonalInfo() {
      let response = request('get', 'wx/user/info');
      response.then(data => {
        if (data.code == 200) {
          let user = {
            nickname: data.data.nickname,
            headPortrait: data.data.headPortrait,
            createTime: timeTransform(new Date(data.data.createTime), "YYYY-MM-DD")
          }
          this.user = user
        } else {
          this.message("查询失败！")
        }
      })
    },

    // goToTrainingRecord() {
    //
    // },
    //
    // goToPaymentRecord() {
    //
    // },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    }

  }

}
</script>