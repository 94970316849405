// /**
//  * 日期选择触发
//  * */
// export default function timeTransform(e) {
//     //获取到的e的数据入下
//     // 0:Tue Jun 01 2021 08:00:00 GMT+0800 (中国标准时间) {}
//     // 1:Fri Jun 11 2021 08:00:00 GMT+0800 (中国标准时间) {}
//
//     let date = []
//     for (const i in e) {
//         date.push(this.gettime(e[i]))
//     }
//     console.log(date, 'handleSelect')
// }
//
// /**
//  * 时间处理函数
//  * */
// function gettime(data) {
//     let value = data.getFullYear() + '-' +
//         this.checkTime(data.getMonth() + 1) + '-' +
//         this.checkTime(data.getDate()) + ' ' +
//         this.checkTime(data.getHours()) + ':' +
//         this.checkTime(data.getMinutes()) + ':' +
//         this.checkTime(data.getSeconds());
//     return value
// }
//
// /**
//  * 时间处理
//  * 如果时间小于10 ，则再前面加一个'0'
//  * */
// function checkTime(i) {
//     if (i < 10) {
//         i = "0" + i
//     }
//     return i;
// }

export default function formatTime(date, formatStr) {
    let arrWeek = ['日', '一', '二', '三', '四', '五', '六'],
        str = formatStr.replace(/yyyy|YYYY/, date.getFullYear()).replace(/yy|YY/, $addZero(date.getFullYear() % 100,
            2)).replace(/mm|MM/, $addZero(date.getMonth() + 1, 2)).replace(/m|M/g, date.getMonth() + 1).replace(
            /dd|DD/, $addZero(date.getDate(), 2)).replace(/d|D/g, date.getDate()).replace(/hh|HH/, $addZero(date
            .getHours(), 2)).replace(/h|H/g, date.getHours()).replace(/ii|II/, $addZero(date.getMinutes(), 2))
            .replace(/i|I/g, date.getMinutes()).replace(/ss|SS/, $addZero(date.getSeconds(), 2)).replace(/s|S/g, date
                .getSeconds()).replace(/w/g, date.getDay()).replace(/W/g, arrWeek[date.getDay()]);
    return str

}

function $addZero(v, size) {
    for (var i = 0, len = size - (v + "").length; i < len; i++) {
        v = "0" + v
    }
    return v + ""
}