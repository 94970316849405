<!--
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 15:02:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\Home.vue
-->
<template>

<!--  头部-->
  <div class="home">
    <mescroll-vue ref="mescroll" :down="mescrollDown" :up="mescrollUp" @init="mescrollInit">
    <div class="home-user-info-div">
      <div class="user-info-img-div">
        <img class="user-info-img" :src="userHeadPortrait"/>
      </div>
      <div class="user-info-name-div">
        {{userNickname}}
      </div>
      <div class="notice-info-div">
        <a class="notice-linked-a" :href="notice.linked">{{notice.title}}</a>
      </div>
    </div>

<!--    轮播图-->
    <div class="home-carousel">
      <el-carousel :interval="5000" height="180px">
        <el-carousel-item v-for="item in newsList" :key="item.id" @click.native="goToNewsDetail(item.id)">
          <img :src="item.img" class="home-carousel-image" :alt="item.title"/>
        </el-carousel-item>
      </el-carousel>
    </div>

<!--    功能区-->
    <div class="home-function">
      <div class="home-function-block-div"  @click="goToCommodityList()">
        <div class="home-function-img-div">
          <img src="../../assets/img/function/course.png" class="home-function-img">
        </div>
        <div class="home-function-word-div">
          课程列表
        </div>
      </div>
      <div class="home-function-block-div" @click="goToTrainingRecord()">
        <div class="home-function-img-div">
          <img src="../../assets/img/function/test.png" class="home-function-img">
        </div>
        <div class="home-function-word-div">
          训练记录
        </div>
      </div>
      <div class="home-function-block-div" @click="goToPaymentRecord()">
        <div class="home-function-img-div">
          <img src="../../assets/img/function/track.png" class="home-function-img">
        </div>
        <div class="home-function-word-div">
          付费记录
        </div>
      </div>
      <div class="home-function-block-div" @click="goToLeaveMessage()">
        <div class="home-function-img-div">
          <img src="../../assets/img/function/message.png" class="home-function-img">
        </div>
        <div class="home-function-word-div">
          留言
        </div>
      </div>
    </div>

<!--  课程列表 -->
    <div class="curriculum-div" v-for="course in courseList" :key="course" @click="goToCourseList(course.id)">
      <div class="curriculum-div-img-div">
        <img class="curriculum-div-img"
             :src="course.img" />
      </div>
      <div class="curriculum-desc-div">
        <tr>
          <td class="curriculum-name-td">{{course.name}}</td>
        </tr>
        <tr>
          <td class="curriculum-desc-td">{{course.desc}}</td>
        </tr>
      </div>
    </div>

    <div class="bottom-div">

    </div>

<!--    <tab-bar>-->
<!--      <tab-bar-item path="/wxIndex">-->
<!--        <img slot="item-icon" src="../../assets/img/tabbar/icon_home.png" alt="首页">-->
<!--        <img slot="item-icon-active" src="../../assets/img/tabbar/icon_home_active.png" alt="首页">-->
<!--        <div slot="item-text">首页</div>-->
<!--      </tab-bar-item>-->
<!--      <tab-bar-item path="/wxPersonal">-->
<!--        <img slot="item-icon" src="../../assets/img/tabbar/icon_home.png" alt="我的">-->
<!--        <img slot="item-icon-active" src="../../assets/img/tabbar/icon_home_active.png" alt="我的">-->
<!--        <div slot="item-text">我的</div>-->
<!--      </tab-bar-item>-->
<!--    </tab-bar>-->
    </mescroll-vue>
  </div>
</template>

<script>

import "../../css/wx/wxIndex.css";
import MescrollVue from 'mescroll.js/mescroll.vue'
// import TabBar from '../../components/tabbar/TabBar.vue'
// import TabBarItem from '../../components/tabbar/TabBarItem.vue'
import store, {request} from "../../store";

export default {
  name: 'WxIndex',
  components: {
    MescrollVue
    // TabBar,
    // TabBarItem
  },

  metaInfo: {
    title: '涛哥交易心理工作室',
  },

  data () {
    return {
      userHeadPortrait: '',
      userNickname: '',
      notice: {
        title: '',
        linked: ''
      },
      courseList: [],
      newsList: [],
      mescrollDown:{
        callback: this.refreshPage,
      },
      mescrollUp: {
        page: {
          num: 1,
          size: 10
        },
        callback: this.loadData,
      },
      mescroll: ''
    }
  },

  mounted() {
    this.initNews();
    this.initCourse();
    this.initUser();
    this.selectNotice();
  },

  onPullDownRefresh() {
    console.log('onPullDownRefresh')
  },

  methods: {
    mescrollInit (mescroll) {
      this.mescroll = mescroll  // 如果this.mescroll对象没有使用到,则mescrollInit可以不用配置
    },

    onPullDownRefresh() {
      console.log('onPullDownRefresh')
    },

    initNews() {
      let response = request('get', '/wx/news/list');
      response.then(data => {
        if (data.code == 200) {
          this.newsList = []
          let newsList = data.data.data;
          for (let i = 0; i < newsList.length; i++) {
            let news = newsList[i]
            let newsData = {
              id: news.id,
              title: news.title,
              img: news.img
            }
            this.newsList.push(newsData)
          }
        }
      })
    },

    initCourse() {
      let param = new Map();
      param.set("status", 'ONLINE');
      param.set("pageNum", 1);
      param.set("pageSize", 10);
      let response = request('get', 'wx/course/list', param);
      response.then(data => {
        if (data.code == 200) {
          this.courseList = []
          let courseList = data.data.data;
          for (let i = 0; i < courseList.length; i++) {
            let course = courseList[i]
            let courseData = {
              id: course.id,
              name: course.name,
              desc: course.desc,
              img: course.img
            }
            this.courseList.push(courseData)
          }
        }
      })
    },

    initUser() {
      if (store.state.userInfo != '') {
        this.userHeadPortrait = store.state.userInfo.headPortrait
        this.userNickname = store.state.userInfo.nickname
      }
      let response = request('get', '/wx/user/info');
      response.then(data => {
        store.state.userInfo = data.data
        this.userHeadPortrait = data.data.headPortrait
        if (this.userNickname.length > 8) {
          this.userNickname = data.data.nickname.substring(0, 6) + '...'
        } else {
          this.userNickname = data.data.nickname
        }

        if (this.mescroll != '') {
          this.mescroll.endSuccess()
        }
      })
    },

    selectNotice() {
      let response = request('get', '/wx/notice/get');
      response.then(data => {
        let notice = data.data
        if (notice != undefined && notice != null && notice != '') {
          this.notice.title = notice.title
          this.notice.linked = notice.linked
        }
      })
    },

    goToCourseList(courseId) {
      this.$router.push({
        path: '/course',
        query: {
          id: courseId
        }
      })
    },

    goToNewsDetail(id) {
      this.$router.push({
        path: '/news',
        query: {
          id: id
        }
      })
    },

    goToCommodityList() {
      this.$router.push({
        path: '/commodity-list',
      })
    },

    goToTrainingRecord() {
      this.$router.push({
        path: '/training-record',
      })
    },

    goToPaymentRecord() {
      this.$router.push({
        path: '/payment-record',
      })
    },

    goToLeaveMessage() {
      if (store.state.userInfo.status == undefined || store.state.userInfo.status == 'UNREGISTERED') {
        this.goToRegister()
        return
      }
      this.$router.push({
        path: '/leave-message',
      })
    },

    goToRegister() {
      this.$router.push({
        path: '/register',
      })
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },

    refreshPage() {
      this.initNews();
      this.initCourse();
      this.initUser();
    },

    loadData(page) {
      console.log(page)
      this.mescroll.endSuccess(1, false)
    }
  }

}
</script>