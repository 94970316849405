<!--
 * @Author: your name
 * @Date: 2021-10-19 11:21:20
 * @LastEditTime: 2021-10-19 14:56:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\views\CourseManager.vue
-->
<template>
  <div id='data-manager' style="padding: 10px 10px 10px; background-color: #F1F4F8">
    <div id="data-header" class="data-header-div">
        <span><strong>协议管理</strong></span>
    </div>
    <div id="data-serch" class="data-serch-div">
      <div id="data-serch-title" class="data-serch-title-div">筛选</div>
      <el-form :inline="true"   class="demo-form-inline1">
        <el-form-item label="状态:">
          <el-select v-model="statusSelect" placeholder="请选择">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="关键字:">-->
<!--          <el-input style="width: 240px" v-model="keyWord" >-->
<!--          </el-input>-->
<!--        </el-form-item>-->
        <el-form-item>
          <el-button type="primary" @click="onSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="clear">清空选项</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div id="data-content" class="data-content-div">
      <div id="data-option" class="data-option-div">
        <div style="text-align: left; width: 50%; color: #333333; font-size: 18px; float:left">协议列表</div>
        <div style="text-align: right; width: 50%; color: #333333; font-size: 18px; float:left">
          <el-button type="primary" @click="createNews">创建协议</el-button>
        </div>
      </div>

      <div id="data-table" class="data-table-div">
        <el-table
            :data="tableData"
            border
            ref="select"
            :header-cell-style="{background:'#F0F4F8', 'text-align':'center'}"
            :cell-style="{'text-align':'center'}"
            style="width: 100%">
          <el-table-column
              prop="id"
              label="id"
              width="60">
          </el-table-column>
          <el-table-column
              prop="name"
              label="协议标题">
          </el-table-column>
          <el-table-column
              prop="desc"
              label="协议内容">
          </el-table-column>
          <el-table-column
              prop="nickName"
              label="创建者昵称">
          </el-table-column>
          <el-table-column
              prop="status"
              label="状态">
          </el-table-column>
          <el-table-column
              prop="createTime"
              label="创建时间">
          </el-table-column>
          <el-table-column
              label="操作"
              width="240">
            <template slot-scope="scope">
              <el-button type="primary" @click="updateNews(scope.row.id)">
                修改
              </el-button>
              <el-button type="primary" v-if="scope.row.status == 'ONLINE'" @click="updateStatus(scope.row.id, 'OFFLINE')">
                下线
              </el-button>
              <el-button type="primary" v-if="scope.row.status == 'OFFLINE'" @click="updateStatus(scope.row.id, 'ONLINE')">
                上线
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
<!--      分页-->
      <div class="el-pagination-div">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totle">
        </el-pagination>
      </div>
    </div>

  </div>
</template>
<script>

import "../../css/paymentProtocol/paymentProtocolManager.css";
import {request, timeTransform} from "../../store";

export default {
  name: 'PaymentProtocolManager',
  data() {
    return {

      keyWord: '',
      statusSelect: '',
      statusList: [],

      pageNum: 1,
      pageSize: 10,
      totle: 0,
      tableData: [],
    }
  },

  mounted() {
    this.initFilterdata();
    this.onSearch();
  },

  methods: {

    // 初始化下拉框数据
    initFilterdata() {
      let response = request('get', 'protocol/filterdata', null);
      response.then(data => {
        for (let i = 0; i < data.data.length; i++) {
          let metadata = data.data[i];
          if (metadata.name === 'STATUS') {
              this.initStatus(metadata);
          }
        }
      });
    },

    initStatus(metadata) {
      let metas = metadata.dataList
      let temp = []
      let all = {};
      all.value = '';
      all.label = '全部';
      temp.push(all);
      for(let i = 0; i < metas.length; i++) {
        let meta = {};
        meta.value = metas[i].name;
        meta.label = metas[i].name;
        temp.push(meta)
      }
      this.statusList = temp;
    },

    updateNews(id) {
      this.$router.push({
        path: '/payment-protocol-update',
        query: {
          id: id
        }
      })
    },

    createNews() {
      this.$router.push({
        path: '/payment-protocol-create',
      })
    },

    clear() {
      this.statusSelect = ''
      this.keyWord = ''
      this.timeRange = [new Date("1970-01-01 00:00"), new Date("2999-12-30 23:59")]
    },

    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      console.log(val)
      this.onSearch()
    },

    handleCurrentChange(val) {
      this.pageNum = val;
      this.onSearch()
    },

    message(msg) {
      const h = this.$createElement;
      this.$message({
        message: h('p', null, [
          h('span', null, msg)
        ])
      });
    },

    // 数据查询
    onSearch() {
      this.pageChange = true;
      let param = new Map();
      if (this.statusSelect != '') {
        param.set("status", this.statusSelect);
      }
      param.set("pageNum", this.pageNum);
      param.set("pageSize", this.pageSize);
      let response = request('get', 'protocol/list', param);
      response.then(data => {
        let newsList = data.data.data;
        this.totle = data.data.count;
        let temp = [];
        for (let i = 0; i < newsList.length; i++) {
          let news = {};
          news.id = newsList[i].id;
          news.name = newsList[i].name;
          news.content = newsList[i].content;
          news.desc = newsList[i].desc;
          news.nickName = newsList[i].nickname;
          news.status = newsList[i].status;
          news.createTime = timeTransform(new Date(newsList[i].createTime), "YYYY-MM-DD HH:ii:ss");
          temp.push(news);
        }
        this.tableData = temp;
      })
    },

    updateStatus(noticeId, status) {
      let response = request('post', 'protocol/update/status?id=' + noticeId + "&status=" + status, null);
      response.then(data => {
        if (data.code == 200) {
          this.message("修改成功！")
          this.onSearch()
        } else {
          this.message("修改失败！")
        }
      })
    }

  },

}
</script>