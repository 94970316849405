/*
 * @Author: your name
 * @Date: 2021-10-19 10:28:55
 * @LastEditTime: 2021-10-19 14:10:28
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \udss-manager\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/LoginPage.vue'
import WxLogin from '../views/WxLoginPage.vue'
import CourseManager from '../views/course/CourseManager';
import CourseCreate from '../views/course/CourseCreate';
import CourseUpdate from '../views/course/CourseUpdate';
import LessonManager from '../views/lesson/LessonManager';
import LessonCreate from '../views/lesson/LessonCreate';
import LessonUpdate from '../views/lesson/LessonUpdate';
import UserManager from '../views/user/UserManager';
import PayManager from '../views/pay/PayManager';

import NewsManager from '../views/news/NewsManager';
import NewsCreate from '../views/news/NewsCreate';
import NewsUpdate from '../views/news/NewsUpdate';

import NoticeManager from '../views/notice/NoticeManager';
import NoticeCreate from '../views/notice/NoticeCreate';
import NoticeUpdate from '../views/notice/NoticeUpdate';

import CommodityManager from '../views/commodity/CommodityManager';
import CommodityCreate from '../views/commodity/CommodityCreate';
import CommodityUpdate from '../views/commodity/CommodityUpdate';

import AutoResponseManager from '../views/autoResponse/AutoResponseManager';
import AutoResponseCreate from '../views/autoResponse/AutoResponseCreate';
import AutoResponseUpdate from '../views/autoResponse/AutoResponseUpdate';

import PaymentProtocolManager from '../views/paymentProtocol/PaymentProtocolManager';
import PaymentProtocolCreate from '../views/paymentProtocol/PaymentProtocolCreate';
import PaymentProtocolUpdate from '../views/paymentProtocol/PaymentProtocolUpdate';

import CommentManager from '../views/comment/CommentManager';
import LeaveMessageManager from '../views/user/LeaveMessageManager';

import WxMessageManager from '../views/message/WxMessageManager';
import WxMessageCreate from '../views/message/WxMessageCreate';
import WxMessageUpdate from '../views/message/WxMessageUpdate';

import TrainManager from '../views/train/TrainManager';



import WxIndex from '../views/wx/WxIndex';
import Person from '../views/wx/PersonalPage';
import Course from '../views/wx/Course';
import MonthlyLesson from '../views/wx/MonthlyLesson';
import MonthlyTraining from '../views/wx/train/MonthlyTraining';
import HundredDays from '../views/wx/HundredDays';
import HundredDaysLesson from '../views/wx/HundredDaysLesson';
import SuperMasterLesson from '../views/wx/SuperMasterLesson';

import BuyCourse from "../views/wx/commodity/BuyCourse";
import CommodityList from "../views/wx/commodity/CommodityList";
import PaymentRecord from "../views/wx/commodity/PaymentRecord";
import TrainingRecord from "../views/wx/train/TrainingCourseList";
import TrainingLessonList from "../views/wx/train/TrainingLessonList";
import TrainingShow from "../views/wx/train/TrainingShow";

import News from "../views/wx/News";
import CommodityDetail from "../views/wx/commodity/CommodityDetail";

import Register from "../views/wx/register/RegisterPage";

import LeaveMessage from "../views/wx/LeaveMessage";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            // keepAlive: true, //需要缓存的页面
            title: "涛哥交易心理工作室"
        },
        metaInfo: {
            title: '涛哥交易心理工作室',
        },
        children:[
            {
                path:'course-manager',
                name:'CourseManager',
                component:CourseManager,
                // meta: {
                //     keepAlive: true//  需要缓存
                // }
            },
            {
                path:'course-create',
                name:'CourseCreate',
                component:CourseCreate
            },
            {
                path:'course-update',
                name:'CourseUpdate',
                component:CourseUpdate
            },
            {
                path:'lesson-manager',
                name:'LessonManager',
                component:LessonManager
            },
            {
                path:'lesson-create',
                name:'LessonCreate',
                component:LessonCreate
            },
            {
                path:'lesson-update',
                name:'LessonUpdate',
                component:LessonUpdate
            },
            {
                path:'user-manager',
                name:'UserManager',
                component:UserManager,
                // meta: {
                //     keepAlive: true//  需要缓存
                // }
            },
            {
                path:'pay-manager',
                name:'PayManager',
                component:PayManager
            },
            {
                path:'news-manager',
                name:'NewsManager',
                component:NewsManager
            },
            {
                path:'news-create',
                name:'NewsCreate',
                component:NewsCreate
            },
            {
                path:'news-update',
                name:'NewsUpdate',
                component:NewsUpdate
            },
            {
                path:'/notice-manager',
                name:'NoticeManager',
                component:NoticeManager
            },
            {
                path:'/notice-create',
                name:'NoticeCreate',
                component:NoticeCreate
            },
            {
                path:'/notice-update',
                name:'NoticeUpdate',
                component:NoticeUpdate
            },
            {
                path:'/commodity-manager',
                name:'CommodityManager',
                component:CommodityManager
            },
            {
                path:'/commodity-create',
                name:'CommodityCreate',
                component:CommodityCreate
            },
            {
                path:'/commodity-update',
                name:'CommodityUpdate',
                component:CommodityUpdate
            },
            {
                path:'/auto-response-manager',
                name:'AutoResponseManager',
                component:AutoResponseManager
            },
            {
                path:'/auto-response-create',
                name:'AutoResponseCreate',
                component:AutoResponseCreate
            },
            {
                path:'/auto-response-update',
                name:'AutoResponseUpdate',
                component:AutoResponseUpdate
            },
            {
                path:'/payment-protocol-manager',
                name:'PaymentProtocolManager',
                component:PaymentProtocolManager
            },
            {
                path:'/payment-protocol-create',
                name:'PaymentProtocolCreate',
                component:PaymentProtocolCreate
            },
            {
                path:'/payment-protocol-update',
                name:'PaymentProtocolUpdate',
                component:PaymentProtocolUpdate
            },
            {
                path:'/comment-manager',
                name:'CommentManager',
                component:CommentManager
            },
            {
                path:'/leave-message-manager',
                name:'LeaveMessageManager',
                component:LeaveMessageManager
            },
            {
                path:'/wx-message-manager',
                name:'WxMessageManager',
                component:WxMessageManager
            },
            {
                path:'/wx-message-create',
                name:'WxMessageCreate',
                component:WxMessageCreate
            },
            {
                path:'/wx-message-update',
                name:'WxMessageUpdate',
                component:WxMessageUpdate
            },
            {
                path:'/train-manager',
                name:'TrainManager',
                component:TrainManager
            },
        ]
    },
    {
        path:'/login',
        name:'Login',
        component:Login
    },
    {
        path:'/wxLogin',
        name:'WxLogin',
        component:WxLogin,
        meta: {
            title: "涛哥交易心理工作室"
        },
        metaInfo: {
            title: '涛哥交易心理工作室',
        }
    },
    {
        path:'/wxIndex',
        name:'WxIndex',
        component:WxIndex,
        meta: {
            title: "涛哥交易心理工作室"
        },
        metaInfo: {
            title: '涛哥交易心理工作室',
        }
    },
    {
        path:'/wxPersonal',
        name:'Person',
        component: Person,
    },
    {
        path:'/course',
        name:'Course',
        component:Course,
        meta: {
            title: "涛哥交易心理工作室"
        },
        metaInfo: {
            title: '涛哥交易心理工作室',
        }
    },
    {
        path:'/monthly-lesson',
        name:'MonthlyLesson',
        component:MonthlyLesson,
        meta: {
            title: "涛哥交易心理工作室"
        },
        metaInfo: {
            title: '涛哥交易心理工作室',
        }
    },
    {
        path:'/monthly-training',
        name:'MonthlyTraining',
        component:MonthlyTraining
    },
    {
        path:'/hundred-days',
        name:'HundredDays',
        component:HundredDays
    },
    {
        path:'/hundred-days-lesson',
        name:'HundredDaysLesson',
        component:HundredDaysLesson
    },
    {
        path:'/super-master-lesson',
        name:'SuperMasterLesson',
        component:SuperMasterLesson
    },
    {
        path:'/buy-course',
        name:'BuyCourse',
        component:BuyCourse
    },
    {
        path:'/commodity-list',
        name:'CommodityList',
        component:CommodityList
    },
    {
        path:'/commodity-detail',
        name:'CommodityDetail',
        component:CommodityDetail
    },
    {
        path:'/payment-record',
        name:'PaymentRecord',
        component:PaymentRecord
    },
    {
        path:'/training-record',
        name:'TrainingRecord',
        component:TrainingRecord,
    },
    {
        path:'/training-lesson-list',
        name:'TrainingLessonList',
        component:TrainingLessonList,
    },
    {
        path:'/training-show',
        name:'TrainingShow',
        component:TrainingShow
    },
    {
        path:'/news',
        name:'News',
        component:News
    },
    {
        path:'/register',
        name:'Register',
        component:Register
    },
    {
        path:'/leave-message',
        name:'LeaveMessage',
        component:LeaveMessage
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
    }
]

const router = new VueRouter({
  routes: routes,
})

export default router
